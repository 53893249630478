import { env } from 'process';

export const VUE_ENV: string = env.VUE_ENV || 'staging';
export const BASE_URL: string = env.VUE_BASE_URL || '';
export const FIREBASE_CONFIG = env.VUE_FIREBASE_CONFIG ? JSON.parse(env.VUE_FIREBASE_CONFIG) : undefined;

export const VALID_EMAIL_DOMAIN = '@joinjobox.com';
export const DEFAULT_AVATAR = 'img/card-1.jpg';

export * from './job-locations.const';
export * from './job.const';
export * from './table-headers.const';
export * from './talent-pool.const';
export * from './user.const';
