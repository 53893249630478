import { ICompany } from '@/interfaces/employer.interface';
import { IWorkspaceUser } from '@/interfaces/user.interface';
import { DocumentData, getDocs, QuerySnapshot, collection } from 'firebase/firestore';
import { db } from '@/config/firebase.conf';

export const mapEmployers = async function (
	employers: QuerySnapshot<DocumentData, DocumentData>,
): Promise<Array<ICompany>> {
	const employerList: Array<ICompany> = await Promise.all(
		employers.docs.map(async (employerSnap: DocumentData) => {
			const employer = employerSnap.data();
			const workSpaceSnapshot = await getDocs(collection(db, 'clients', employerSnap.id, 'invitedClients'));
			const talentSnapshot = await getDocs(collection(db, 'clients', employerSnap.id, 'talentPools'));
			const talentPools: string[] = talentSnapshot.docs.map(doc => doc.id);

			return {
				addressLine1: employer.addressLine1,
				city: employer.city,
				companyCategory: employer.companyCategory,
				companyName: employer.companyName,
				industry: employer.industry,
				postalCode_zipCode: employer.postalCode_zipCode,
				vat: employer.vat,
				website: employer.website,
				userId: employer.userId,
				workspaceUsers: await mapWorkSpaceUsers(workSpaceSnapshot),
				talentPools: talentPools,
			};
		}),
	);
	return employerList;
};

const mapWorkSpaceUsers = async (
	invitedClients: QuerySnapshot<DocumentData, DocumentData>,
): Promise<Map<string, IWorkspaceUser>> => {
	const invitedClientMap: Map<string, IWorkspaceUser> = new Map();

	for (const invitedClientsSnap of invitedClients.docs) {
		const invitedClient = invitedClientsSnap.data();

		const workSpaceUser: IWorkspaceUser = {
			email: invitedClient.email,
			name: invitedClient.name,
			status: invitedClient.status,
			surname: invitedClient.surname,
		};
		invitedClientMap.set(invitedClientsSnap.id, workSpaceUser);
	}

	return invitedClientMap;
};
