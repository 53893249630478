import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { Service } from 'typedi';

import { db } from '@/config/firebase.conf';
import { IJob, IStudentApplication, IUpdateJobDto } from '@/interfaces/job.interface';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import {
	mapJobs,
	mapStudentApplications,
	mapUpdateJobToJob,
	mapUpdateJobToMicro,
	mapUpdateJobToSkills,
} from '@/mappers/job.mapper';

@Service()
export class JobService {
	/**
	 * @method getJobs
	 * @async
	 * @returns {Promise<IPaginatedItems<IJob>>}
	 */
	async getJobs(): Promise<IPaginatedItems<IJob>> {
		const jobsSnapshot = await getDocs(collection(db, 'jobs'));

		return mapJobs(jobsSnapshot);
	}

	/**
	 * @method getJobApplications
	 * @async
	 * @param {string} jobId
	 * @returns {Promise<IPaginatedItems<IStudentApplication>>}
	 */
	async getJobApplications(jobId: string): Promise<IPaginatedItems<IStudentApplication>> {
		const q = query(collection(db, 'applications'), where('jobId', '==', jobId));
		const applicationSnapshot = await getDocs(q);

		return mapStudentApplications(applicationSnapshot);
	}

	/**
	 * @method verifyJob
	 * @async
	 * @param {string} jobId
	 * @param {boolean} verified
	 * @returns {Promise<IPaginatedItems<IStudentApplication>>}
	 */
	async verifyJob(jobId: string, verified: boolean): Promise<void> {
		await updateDoc(doc(db, 'jobs', jobId), {
			verified: verified,
		});
	}

	/**
	 * @method closeJob
	 * @async
	 * @param {string} jobId
	 * @param {boolean} closed
	 * @returns {Promise<void>}
	 */
	async closeJob(jobId: string, closed: boolean): Promise<void> {
		await updateDoc(doc(db, 'jobs', jobId), {
			jobClosed: closed,
		});
	}

	/**
	 * @method updateJob
	 * @async
	 * @param {string} jobId
	 * @param {IUpdateJobDto} job
	 * @returns {Promise<void>}
	 */
	async updateJob(jobId: string, updatedJob: IUpdateJobDto): Promise<void> {
		const updateCollection = async (collectionName: string, mapper: (data: IUpdateJobDto) => any) => {
			const data = mapper(updatedJob);
			const querySnapshot = await getDocs(query(collection(db, collectionName), where('jobId', '==', jobId)));

			if (!querySnapshot.empty) {
				const docRef = querySnapshot.docs[0].ref;
				await updateDoc(docRef, this.filterUndefinedFields(data) as Record<string, any>);
			}
		};

		await Promise.all([
			updateCollection('micros', mapUpdateJobToMicro),
			updateCollection('skills', mapUpdateJobToSkills),
		]);

		const jobData = mapUpdateJobToJob(updatedJob);
		await updateDoc(doc(db, 'jobs', jobId), this.filterUndefinedFields(jobData) as Record<string, any>);
	}

	// This is an utility
	filterUndefinedFields(obj: any): Record<string, any> {
		return Object.fromEntries(
			Object.entries(obj)
				.filter(([_, v]) => v !== undefined)
				.map(([k, v]) => [k, v && typeof v === 'object' && !Array.isArray(v) ? this.filterUndefinedFields(v) : v]),
		);
	}
}
