<template>
	<v-dialog v-model="openSpecialProgramTalentPool" max-width="700px">
		<v-card>
			<v-toolbar flat class="fixed-bar" color="#e66832">
				<v-toolbar-title>
					<template v-if="isEditSpecialProgramTalentPool">Edit Special Program Talent Pool</template>
					<template v-else>Create Special Program Talent Pool</template>
				</v-toolbar-title>

				<v-spacer></v-spacer>
				<v-btn icon @click="closeTalentPool">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-item>
				<v-text-field
					hint="Enter the name of the Special Program Talent Pool"
					type="input"
					label="Special Program Talent Pool Name"
					clearable
					clear-icon="mdi-close-circle"
					v-model="specialProgramTalentPoolName"
					:error-messages="specialProgramTalentPoolNameError"
				></v-text-field>
			</v-card-item>
			<v-card-item>
				<v-textarea
					label="Special Program Talent Pool Description"
					clearable
					clear-icon="mdi-close-circle"
					hint="Enter the description of the Special Program Talent Pool"
					v-model="specialProgramtalentPoolDescription"
					:error-messages="specialProgramTalentPoolDescriptionError"
				></v-textarea
			></v-card-item>
			<v-card-actions class="d-flex flex-row justify-start">
				<v-btn
					v-if="!isEditSpecialProgramTalentPool"
					class="btn-jobox"
					text="Submit"
					@click="createSpecialProgramTalentPool"
				></v-btn>
				<v-btn
					v-if="isEditSpecialProgramTalentPool"
					class="btn-jobox"
					text="Edit Special Program Talent Pool"
					@click="editSpecialProgramTalentPool"
				></v-btn>
				<v-btn text="Cancel" @click="closeTalentPool"></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, Ref, ref, watch } from 'vue';
import { ActionTypes } from '@/store/modules/specialProgram/action-types';
import { useStore } from 'vuex';

const props = defineProps({
	openSpecialProgramTalentPool: {
		type: Boolean,
		default: false,
	},
	isEditSpecialProgramTalentPool: {
		type: Boolean,
		default: false,
	},
	currentTalentPool: {
		type: Object,
		default: null,
	},
});

const specialProgramTalentPoolNameError = ref('');
const specialProgramTalentPoolDescriptionError = ref('');
const openSpecialProgramTalentPool = computed(() => props.openSpecialProgramTalentPool);
const specialProgramtalentPoolDescription: Ref<string> = ref('');
const specialProgramTalentPoolName: Ref<string> = ref('');
const talentPoolId: Ref<string> = ref('');
const store = useStore();

const emit = defineEmits(['closeTalentPool']);

watch(
	() => props.currentTalentPool,
	newValue => {
		if (props.isEditSpecialProgramTalentPool && newValue) {
			specialProgramTalentPoolName.value = newValue.name || '';
			specialProgramtalentPoolDescription.value = newValue.description || '';
			talentPoolId.value = newValue.talentPoolId;
		} else {
			specialProgramTalentPoolName.value = '';
			specialProgramtalentPoolDescription.value = '';
		}
	},
	{ immediate: true },
);

const closeTalentPool = () => {
	emit('closeTalentPool', false);
};
const createSpecialProgramTalentPool = async () => {
	if (validateFields()) {
		await store.dispatch(ActionTypes.CREATE_SPECIAL_PROGRAM_TALENT_POOL, {
			name: specialProgramTalentPoolName.value,
			description: specialProgramtalentPoolDescription.value,
		});
		alert(`Talent Pool ${specialProgramTalentPoolName.value} has been created.`);
		emit('closeTalentPool');
	}
};
const editSpecialProgramTalentPool = async () => {
	await store.dispatch(ActionTypes.EDIT_SPECIAL_PROGRAM_TALENT_POOL, {
		talentPoolId: talentPoolId.value,
		name: specialProgramTalentPoolName.value,
		description: specialProgramtalentPoolDescription.value,
	});
	emit('closeTalentPool');
	alert(`Successful edit for  ${specialProgramTalentPoolName.value} `);
};

const validateFields = () => {
	let isValid = true;

	if (!specialProgramTalentPoolName.value.trim()) {
		specialProgramTalentPoolNameError.value = ' Special Program Talent Pool Name is required';
		isValid = false;
	} else {
		specialProgramTalentPoolNameError.value = '';
	}

	if (!specialProgramtalentPoolDescription.value.trim()) {
		specialProgramTalentPoolDescriptionError.value = 'Special Program Talent Pool Description is required';
		isValid = false;
	} else {
		specialProgramTalentPoolDescriptionError.value = '';
	}

	return isValid;
};
</script>
