import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { rootState, RootState } from '../root/state';
import { IBursary, IStudentApplication } from '@/interfaces/bursary.interface';

export const state = {
	...rootState,
	bursaryList: <IPaginatedItems<IBursary>>{
		currentPage: 0,
		previousPage: 0,
		itemsPerPage: 0,
		totalPages: 0,
		totalItems: 0,
		records: new Map(),
	},
	bursary: <IBursary | undefined>{},
	bursaryApplicants: <IPaginatedItems<IStudentApplication>>{
		currentPage: 0,
		previousPage: 0,
		itemsPerPage: 0,
		totalPages: 0,
		totalItems: 0,
		records: new Map(),
	},
};

export type State = typeof state & RootState;
