import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { rootState, RootState } from '../root/state';
import { ITalentPool } from '@/interfaces/talentPool.interface';

export const state = {
	...rootState,
	talentPoolList: <IPaginatedItems<ITalentPool>>{
		currentPage: 0,
		previousPage: 0,
		itemsPerPage: 0,
		totalPages: 0,
		totalItems: 0,
		records: new Map(),
	},
	talentPool: <ITalentPool | undefined>{},
};

export type State = typeof state & RootState;
