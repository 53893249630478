export const JOB_TABLE_HEADER = [
	{ key: 'companyName', title: 'Company Name', align: 'start', sortable: true },
	{ key: 'jobName', title: 'Job Name' },
	{ key: 'jobRole', title: 'Job Role' },
	{ key: 'startDate', title: 'Start Date' },
	{ key: 'status', title: 'Status' },
	{ key: 'actions', title: 'Actions', align: 'end', sortable: false },
];

export const APPLICANT_TABLE_HEADER = [
	{ key: 'name', title: 'Name', align: 'start', sortable: true },
	{ key: 'yearOfStudy', title: 'Year of Study' },
	{ key: 'degree', title: 'Degree' },
	{ key: 'applicationStatus', title: 'Status' },
	{ key: 'actions', title: 'Actions', align: 'end', sortable: false },
];

export const BURSARY_TABLE_HEADER = [
	{ key: 'title', title: 'Title' },
	{ key: 'companyName', title: 'Company Name', align: 'start', sortable: true },
	{ key: 'applicationDeadline', title: 'Application Deadline' },
	{ key: 'verified', title: 'Status' },
	{ key: 'actions', title: 'Actions', align: 'end', sortable: false },
];

export const COMPANY_TABLE_HEADER = [
	{ key: 'companyName', title: 'Company Name', align: 'start', sortable: true },
	{ key: 'companyCategory', title: 'Company Category', align: 'start', sortable: true },
	{ key: 'industry', title: 'Industry', align: 'start', sortable: true },
	{ key: 'actions', title: 'Actions', align: 'end', sortable: false },
];
