import { MutationTree } from 'vuex';

import { MutationTypes } from './mutation-types';
import { State } from './state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { ISpecialProgramTalentPool } from '@/interfaces/specialProgramTalentPool.interface';

export type Mutations<S = State> = {
	[MutationTypes.SET_SPECIAL_PROGRAM_TALENT_POOL_LIST](
		state: S,
		payload: IPaginatedItems<ISpecialProgramTalentPool>,
	): void;
	[MutationTypes.SET_SPECIAL_PROGRAM_TALENT_POOL](state: S, payload: ISpecialProgramTalentPool): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_SPECIAL_PROGRAM_TALENT_POOL_LIST](
		state: State,
		payload: IPaginatedItems<ISpecialProgramTalentPool>,
	) {
		state.specialProgramTalentPoolList = payload;
	},
	[MutationTypes.SET_SPECIAL_PROGRAM_TALENT_POOL](state: State, payload: ISpecialProgramTalentPool) {
		state.specialPoolTalentPool = payload;
	},
};
