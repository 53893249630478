import { ActionContext, ActionTree } from 'vuex';
import Container from 'typedi';

import { ActionTypes } from './action-types';
import { State } from './state';
import { RootMutationTypes } from '../root/mutation-types';
import { RootState } from '../root/state';
import { JobService } from '@/services/job.service';
import { ApplicationService } from '@/services/application.service';
import { MutationTypes } from './mutation-types';
import { APPLICATION_STATUS } from '@/constants';
import { IUpdateJobDto } from '@/interfaces/job.interface';

export const jobService = Container.get(JobService);
const applicationService = Container.get(ApplicationService);

export interface Actions {
	[ActionTypes.GET_JOB_LIST]({ commit }: ActionContext<State, RootState>): void;
	[ActionTypes.GET_JOB_APPLICANTS]({ commit }: ActionContext<State, RootState>, payload: { jobId: string }): void;
	[ActionTypes.CLOSE_JOB](
		{ commit }: ActionContext<State, RootState>,
		payload: { jobId: string; closed: boolean },
	): void;
	[ActionTypes.VERIFY_JOB](
		{ commit }: ActionContext<State, RootState>,
		payload: { jobId: string; verified: boolean },
	): void;
	[ActionTypes.UPDATE_APPLICANT_STATUS](
		{ commit }: ActionContext<State, RootState>,
		payload: { jobId: string; applicationId: string; status: APPLICATION_STATUS },
	): void;
	[ActionTypes.UPDATE_JOB](
		{ commit }: ActionContext<State, RootState>,
		payload: { jobId: string; job: IUpdateJobDto },
	): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.GET_JOB_LIST]({ commit }) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const jobList = await jobService.getJobs();
			commit(MutationTypes.SET_JOB_LIST, jobList);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, e?.message || 'Problems with getting jobs. Please try again later.');
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.GET_JOB_APPLICANTS]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const applicantList = await jobService.getJobApplications(payload.jobId);
			const studentIds = [...applicantList.records.values()].map(s => s.alias);
			commit(MutationTypes.SET_JOB_APPLICANTS, applicantList);
			if (studentIds?.length) {
				await dispatch('GET_STUDENT_LIST', { studentIds });
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with getting applicants. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.CLOSE_JOB]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await jobService.closeJob(payload.jobId, payload.closed);
			await dispatch(ActionTypes.GET_JOB_LIST);
			commit(MutationTypes.SET_JOB, { jobId: payload.jobId });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, e?.message || 'Problems with closing the job. Please try again later.');
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.VERIFY_JOB]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await jobService.verifyJob(payload.jobId, payload.verified);
			await dispatch(ActionTypes.GET_JOB_LIST);
			commit(MutationTypes.SET_JOB, { jobId: payload.jobId });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, e?.message || 'Problems with verifying the job. Please try again later.');
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.UPDATE_APPLICANT_STATUS]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await applicationService.updateApplicantStatus(payload.applicationId, payload.status);
			await dispatch(ActionTypes.GET_JOB_APPLICANTS, { jobId: payload.jobId });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with shortlisting the applicant. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.UPDATE_JOB]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await jobService.updateJob(payload.jobId, payload.job);
			dispatch(ActionTypes.GET_JOB_LIST);
			commit(MutationTypes.SET_JOB, { jobId: payload.jobId });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, e?.message || 'Problems with getting jobs. Please try again later.');
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
};
