<template>
	<div>
		<v-select
			clearable
			label="Do you want a written submission included in the application?"
			:items="['Yes', 'No']"
			v-model="writtenSubmission"
		></v-select>
		<QuillEditor
			v-if="writtenSubmission === 'Yes'"
			theme="snow"
			:content="writtenSubmissionDescription"
			@update:content="value => (writtenSubmissionDescription = value)"
			contentType="html"
			:style="{ height: '400px' }"
		/>
		<br />
		<v-select
			clearable
			label="Do you want a video submission included in the application?"
			:items="['Yes', 'No']"
			v-model="videoSubmission"
		></v-select>
		<QuillEditor
			v-if="videoSubmission === 'Yes'"
			theme="snow"
			:content="videoSubmissionDescription"
			@update:content="value => (writtenSubmissionDescription = value)"
			contentType="html"
			:style="{ height: '400px' }"
		/>
	</div>
</template>

<script setup>
import { ref, defineEmits, onUpdated, computed } from 'vue';
import { QuillEditor } from '@vueup/vue-quill';
import { useStore } from 'vuex';

const store = useStore();
const job = computed(() => store.state.job.job);
const writtenSubmission = ref(job.value.additionalSubmissions.writtenSubmission);
const writtenSubmissionDescription = ref(job.value.additionalSubmissions.writtenSubmissionDescription);
const videoSubmission = ref(job.value.additionalSubmissions.videoSubmission);
const videoSubmissionDescription = ref(job.value.additionalSubmissions.videoSubmissionDescription);

// Emits
const emit = defineEmits([
	'writtenSubmission',
	'writtenSubmissionDescription',
	'videoSubmission',
	'videoSubmissionDescription',
]);

onUpdated(() => {
	emit('writtenSubmission', writtenSubmission.value);
	emit('writtenSubmissionDescription', writtenSubmissionDescription.value);
	emit('videoSubmission', videoSubmission.value);
	emit('videoSubmissionDescription', videoSubmissionDescription.value);
});
</script>

<style lang="scss" scoped></style>
