import 'reflect-metadata';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { browserSessionPersistence, setPersistence } from 'firebase/auth';
import { auth } from './config/firebase.conf';

const vuetify = createVuetify({
	components,
	directives,
});

await setPersistence(auth, browserSessionPersistence);

createApp(App).use(vuetify).use(store).use(router).mount('#app');
