<template>
	<div class="table-container">
		<div class="justify-end">
			<v-btn
				class="btn-filter btn-download"
				prepend-icon="mdi-filter-outline"
				variant="outlined"
				@click="toggleFilterMenu"
				>Filter</v-btn
			>
			<v-btn class="btn-download" prepend-icon="mdi-download" variant="outlined" @click="downloadCsv()">
				Download CSV
			</v-btn>
		</div>

		<v-card title="Bursary Application List" flat>
			<template v-slot:text>
				<v-text-field
					v-model="search"
					label="Search"
					prepend-inner-icon="mdi-magnify"
					variant="outlined"
					hide-details
					single-line
				></v-text-field>
			</template>

			<v-data-table
				:headers="APPLICANT_TABLE_HEADER"
				:items="filteredBursariesApplicants"
				:search="search"
				:loading="loading"
				class="elevation-1"
				show-select
				v-model="selectedBursaryStudents"
				return-object
			>
				<template #[`item.actions`]="{ item }">
					<div style="text-align: right">
						<v-btn
							class="btn-jobox"
							prepend-icon="mdi-account-outline"
							style="margin-right: 1rem; text-align: right"
							@click="viewStudent(item.alias)"
							>View Profile</v-btn
						>
						<v-menu>
							<template #activator="{ props }">
								<v-btn color="transparent" v-bind="props" flat> <v-icon>mdi-dots-vertical</v-icon> </v-btn>
							</template>
							<v-list>
								<v-list-item
									v-if="item.applicationStatus === APPLICATION_STATUS.APPLIED"
									@click="screenDocuments(item.id, item)"
								>
									<v-list-item-title>Screen Documents</v-list-item-title>
								</v-list-item>
								<v-list-item
									v-if="item.applicationStatus === APPLICATION_STATUS.APPLIED"
									@click="shortlistApplicant(item)"
								>
									<v-list-item-title>Shortlist Candidate</v-list-item-title>
								</v-list-item>
								<v-list-item
									v-if="item.applicationStatus === APPLICATION_STATUS.SHORTLIST"
									@click="removeShortlistedApplicant(item)"
								>
									<v-list-item-title>Remove Candidate</v-list-item-title>
								</v-list-item>
								<v-list-item v-if="item.videoSubmissionUrl" @click="viewSubmission(item.videoSubmissionUrl)">
									<v-list-item-title>View Video Submission</v-list-item-title>
								</v-list-item>
								<v-list-item v-if="item.writtenSubmissionUrl" @click="viewSubmission(item.writtenSubmissionUrl)">
									<v-list-item-title>View Written Submission</v-list-item-title>
								</v-list-item>
								<v-list-item v-if="checkOptions(item)">
									<v-list-item-title>No Options Available</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
				</template>
			</v-data-table>
		</v-card>
		<ApplicationsListFilter
			v-if="isFilterClicked"
			:isFilterClicked="isFilterClicked"
			@update:degree="handleDegreeChange"
			@update:yearsOfStudy="handleYearsOfStudyChange"
			@update:isClicked="handleFilterClose"
			@update:status="handleStatusChange"
			@update:town="handleTownChange"
			@update:province="handleProvinceChange"
			:applicants="applicantList"
		/>
		<ScreenDocuments
			v-if="submittedDocuments.length"
			:bursaryApplicationId="bursaryApplicationId"
			:submittedDocuments="submittedDocuments"
			:openScreenDocuments="openScreenDocuments"
			@update:openScreenDocuments="openScreenDocuments = $event"
			@reviewCompleted="handleReviewCompleted"
		/>
		<ViewSubmission
			:openSubmission="openSubmission"
			:submissionUrl="submissionUrl"
			@closeSubmission="handleCloseSubmission"
		/>
		<StudentModal v-if="isStudentModalOpen" @close="closeStudentModal" />
	</div>
</template>

<script lang="ts" setup>
import { ref, computed, Ref, ComputedRef, watch } from 'vue';
import { APPLICANT_TABLE_HEADER, APPLICATION_STATUS } from '@/constants';
import { MutationTypes } from '@/store/modules/student/mutation-types';
import { useStore } from 'vuex';

import StudentModal from '@/components/StudentModal.vue';
import ViewSubmission from '@/components/ViewSubmission.vue';
import { IBursary, IBursaryDocument, IStudentApplication } from '@/interfaces/bursary.interface';
import { ActionTypes } from '@/store/modules/bursary/action-types';
import { useRoute } from 'vue-router';
import ScreenDocuments from '@/components/ScreenDocuments.vue';
import ApplicationsListFilter from '@/components/Filters/ApplicationsListFilter.vue';
import { IStudentUser } from '@/interfaces/student.interface';
import { exportCsv } from '@/utils/file.util';
import { IEmailPayload } from '@/interfaces';
import { sendEmail } from '@/functions';

const store = useStore();
const route = useRoute();
const search: Ref<string> = ref('');
const loading: ComputedRef<boolean> = computed(() => store.state.root.LOADING);
const submissionUrl: Ref<string> = ref('');
const openSubmission: Ref<boolean> = ref(false);
const isStudentModalOpen: Ref<boolean> = ref(false);
const isFilterClicked: Ref<boolean> = ref(false);
const selectedTowns: Ref<string[]> = ref([]);
const selectedProvinces: Ref<string[]> = ref([]);
const selectedStatus: Ref<APPLICATION_STATUS | null> = ref(null);
const selectedYearsOfStudy: Ref<string[]> = ref([]);
const selectedDegrees: Ref<string[]> = ref([]);
const submittedDocuments: Ref<IBursaryDocument[]> = ref([]);
const bursaryApplicationId: Ref<string> = ref('');
const openScreenDocuments: Ref<boolean> = ref(false);
const bursary: ComputedRef<IBursary> = computed(() => store.state.bursary.bursary);
const currentApplicant: Ref<IStudentApplication | null> = ref(null);

const viewStudent = (studentId: string) => {
	store.commit(MutationTypes.SET_STUDENT, { studentId });
	isStudentModalOpen.value = true;
};

const studentMainList: ComputedRef<IStudentUser[]> = computed(() => [
	...store.state.student.studentList.records.values(),
]);
const shortlistApplicant = async (applicant: IStudentApplication) => {
	const shortlist = confirm(`Are you sure you want to shortlist ${applicant.name}?`);
	if (shortlist) {
		await store.dispatch(ActionTypes.UPDATE_BURSARY_APPLICANT_STATUS, {
			bursaryId: route.params.jobId,
			bursaryApplicationId: applicant.id,
			status: APPLICATION_STATUS.SHORTLIST,
		});
		alert(`${applicant.name} has been shortlisted!`);
	}
};

const removeShortlistedApplicant = async (applicant: IStudentApplication) => {
	const shortlist = confirm(`Are you sure you want to remove ${applicant.name} from shortlist?`);
	if (shortlist) {
		await store.dispatch(ActionTypes.UPDATE_BURSARY_APPLICANT_STATUS, {
			bursaryId: route.params.jobId,
			bursaryApplicationId: applicant.id,
			status: APPLICATION_STATUS.APPLIED,
		});
		alert(`${applicant.name} has been remove from shortlist!`);
	}
};

const checkOptions = (item: {
	writtenSubmissionUrl: string;
	videoSubmissionUrl: string;
	applicationStatus: APPLICATION_STATUS;
}) => {
	const noSubmissionUrls = !item.writtenSubmissionUrl && !item.videoSubmissionUrl;
	const isNotShortlistOrApplied =
		item.applicationStatus !== APPLICATION_STATUS.SHORTLIST && item.applicationStatus !== APPLICATION_STATUS.APPLIED;
	return noSubmissionUrls && isNotShortlistOrApplied;
};

const closeStudentModal = () => {
	isStudentModalOpen.value = false;
};

const screenDocuments = (id: string, applicant: IStudentApplication) => {
	bursaryApplicationId.value = id;
	currentApplicant.value = applicant;
	submittedDocuments.value = [...applicant.submittedDocuments];
	openScreenDocuments.value = true;
};

const handleReviewCompleted = async (reviewedDocs: IBursaryDocument[]) => {
	const rejectedDocuments =
		reviewedDocs?.filter(doc => !!doc.comments?.comment).map(doc => `${doc.name} - ${doc.comments?.comment}`) || [];

	const data: IEmailPayload[] = [
		{
			useTemplate: true,
			templateId: 'd-c0292133f7e145b29abb47570281d793',
			to: currentApplicant?.value?.applicantEmail || '',
			dynamicTemplateData: {
				studentName: currentApplicant?.value?.name ?? '',
				bursaryName: bursary.value.title,
				companyName: bursary.value.companyName,
				rejectedDocuments,
			},
		},
	];

	if (rejectedDocuments?.length && !currentApplicant?.value?.resubmitEmailSent) {
		await sendEmail(data);
		await store.dispatch(ActionTypes.RESUBMIT_EMAIL_SENT, bursaryApplicationId.value);
	}

	alert(
		`${currentApplicant.value?.name} has been notified of rejected documents on ${currentApplicant.value?.applicantEmail}!`,
	);
	currentApplicant.value = null;
	openScreenDocuments.value = false;
};

const viewSubmission = (submissionsUrl: string) => {
	submissionUrl.value = submissionsUrl;
	openSubmission.value = true;
};

const toggleFilterMenu = () => {
	isFilterClicked.value = !isFilterClicked.value;
};

const handleFilterClose = (filterClose: boolean) => {
	isFilterClicked.value = filterClose;
};

const handleYearsOfStudyChange = (newYearsOfStudy: string[]) => {
	selectedYearsOfStudy.value = newYearsOfStudy;
};

const handleDegreeChange = (newDegrees: string[]) => {
	selectedDegrees.value = newDegrees;
};

const handleTownChange = (newTowns: string[]) => {
	selectedTowns.value = newTowns;
};

const handleProvinceChange = (newProvinces: string[]) => {
	selectedProvinces.value = newProvinces;
};

const handleStatusChange = (newStatus: APPLICATION_STATUS | null) => {
	selectedStatus.value = newStatus;
};

const handleCloseSubmission = (open: boolean) => {
	openSubmission.value = open;
};

const getApplicants = async () => {
	await store.dispatch(ActionTypes.GET_BURSARY_APPLICANTS, { bursaryId: route.params.bursaryId });
};

getApplicants();

const applicantList: ComputedRef<IStudentApplication[]> = computed(() => [
	...store.state.bursary.bursaryApplicants.records.values(),
]);

const selectedBursaryStudents = ref<IStudentApplication[]>([]);

const filteredBursariesApplicants: ComputedRef<IStudentApplication[]> = computed(() => {
	const filtered =
		applicantList.value?.filter((applicant: IStudentApplication) => {
			const studentLocation = getStudentLocation(applicant.alias);

			return (
				(selectedYearsOfStudy.value.length === 0 || selectedYearsOfStudy.value.includes(applicant.yearOfStudy)) &&
				(selectedDegrees.value.length === 0 || selectedDegrees.value.includes(applicant.degree)) &&
				(selectedTowns.value.length === 0 || selectedTowns.value.includes(studentLocation.town)) &&
				(selectedProvinces.value.length === 0 || selectedProvinces.value.includes(studentLocation.province)) &&
				(selectedStatus.value === null || applicant.applicationStatus === selectedStatus.value)
			);
		}) || [];

	return filtered;
});

const getStudentLocation = (studentId: string) => {
	const studentListState = store.state.student.studentList;
	const student: IStudentUser = studentListState.records.get(studentId);
	return student ? student.location : { town: '', province: '' };
};

const downloadCsv = () => {
	const bursaryStudents =
		selectedBursaryStudents.value.length === 0 ? filteredBursariesApplicants.value : selectedBursaryStudents.value;
	const rows = bursaryStudents.map(applicant => {
		const nameParts = applicant.name.trim().split(' ');
		const student = studentMainList.value.find(student => student.alias === applicant.alias);
		return {
			FirstName: nameParts[0],
			LastName: nameParts[1],
			Email: student ? student.email : '',
			PhoneNumber: student ? student.phone : '',
		};
	});
	exportCsv(rows, 'bursary_applicants');
};
</script>
<style lang="scss" scoped>
.table-container {
	padding: 2rem;
}
.btn-download {
	margin: 0 1rem 15px;
}
</style>
