import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { FIREBASE_CONFIG } from '@/constants';

const firebaseConfig = FIREBASE_CONFIG || {
	apiKey: 'AIzaSyCbw3cELtpTrHJE_d3nU7P22_rxVrjgWEc',
	authDomain: 'jobox-staging-fcb0f.firebaseapp.com',
	databaseURL: 'https://jobox-staging-fcb0f-default-rtdb.firebaseio.com',
	projectId: 'jobox-staging-fcb0f',
	storageBucket: 'jobox-staging-fcb0f.appspot.com',
	messagingSenderId: '1083723251750',
	appId: '1:1083723251750:web:cd10b68a62bd8ff9c1a6e7',
	measurementId: 'G-BQFDKYP4QP',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp, 'us-central1');
export const storage = getStorage(firebaseApp);
