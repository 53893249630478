import { createStore } from 'vuex';

import { RootModule } from './modules/root';
import { AuthModule } from './modules/auth';
import { JobModule } from './modules/job';
import { StudentModule } from './modules/student';
import { EmployerModule } from './modules/employer';
import { SettingsModule } from './modules/settings';
import { BursaryModule } from './modules/bursary';
import { TalentPoolModule } from './modules/talentPool';
import { SpecailProgramTalentPoolModule } from './modules/specialProgram';

export default createStore({
	modules: {
		root: RootModule,
		auth: AuthModule,
		job: JobModule,
		student: StudentModule,
		employer: EmployerModule,
		settings: SettingsModule,
		bursary: BursaryModule,
		talentPool: TalentPoolModule,
		specialProgramTalentPool: SpecailProgramTalentPoolModule,
	},
});
