import { RouteRecordRaw } from 'vue-router';
import SpecialProgram from '@/views/Dashboard/specialProgram/SpecialProgram.vue';
import BaseLayout from '@/components/BaseLayout.vue';
import store from '@/store';
import { ActionTypes } from '@/store/modules/specialProgram/action-types';
import { ActionTypes as EmployerActionTypes } from '@/store/modules/employer/action-types';
import ViewSpecialProgramTalentPool from '@/views/Dashboard/specialProgram/ViewSpecialProgramTalentPool.vue';

export const SpecialProgramRoute: RouteRecordRaw = {
	path: '/special-program',
	component: BaseLayout,
	children: [
		{
			path: '',
			name: 'special-program',
			component: SpecialProgram,
			beforeEnter: async () => {
				await store.dispatch(ActionTypes.GET_SPECIAL_PROGRAM_TALENT_POOL_LIST);
				await store.dispatch(EmployerActionTypes.GET_EMPLOYERS);
			},
		},
		{
			path: '/:specialProgramtalentPoolId',
			name: 'special-program-talent-pool',
			component: ViewSpecialProgramTalentPool,
			beforeEnter: async (to, from, next) => {
				try {
					await store.dispatch(ActionTypes.GET_SPECIAL_PROGRAM_TALENT_POOL, {
						specialProgramtalentPoolId: to.params.specialProgramtalentPoolId,
					});
					next();
				} catch (error) {
					console.error('Error fetching talent pool:', error);
					next(false);
				}
			},
		},
	],
};
