<template>
	<div>
		<Suspense>
			<v-card flat>
				<v-card-title> Special Program Talent Pool: {{ talentPoolName }} </v-card-title>
				<template v-slot:text>
					<div class="d-flex flex-row justify-space-between">
						<v-text-field
							v-model="search"
							label="Search"
							prepend-inner-icon="mdi-magnify"
							variant="outlined"
							hide-details
							single-line
							class="mr-4"
							style="flex: 2"
						></v-text-field>
					</div>
				</template>
				<div>
					<v-data-table
						:headers="SPECIAL_PROGRAM_TALENT_POOL_STUDENTS"
						:search="search"
						:items="studentList"
						class="elevation-1"
					>
						<template #[`item.actions`]="{ item }">
							<div style="text-align: right">
								<v-btn class="btn-jobox" prepend-icon="mdi-eye-outline" @click="viewStudent(item.alias)"
									>View Student</v-btn
								>
								<v-menu>
									<template #activator="{ props }">
										<v-btn color="transparent" v-bind="props" flat>
											<v-icon>mdi-dots-horizontal</v-icon>
										</v-btn>
									</template>
									<v-list>
										<v-list-item @click="verifyStudent(item)">
											{{ item.isSpecialProgramVerified ? 'Unverify Student' : 'Verify Student' }}
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</template>
					</v-data-table>
				</div>
			</v-card>
		</Suspense>
		<StudentModal v-if="isStudentModalOpen" @close="closeStudentModal" />
	</div>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, ref } from 'vue';
import { useStore } from 'vuex';
import { ISpecialProgramTalentPool } from '@/interfaces/specialProgramTalentPool.interface';
import { SPECIAL_PROGRAM_TALENT_POOL_STUDENTS } from '@/constants/talent-pool.const';
import { MutationTypes } from '@/store/modules/student/mutation-types';
import StudentModal from '@/components/StudentModal.vue';
import { ActionTypes as StudentActionTypes } from '@/store/modules/student/action-types';
const store = useStore();
const search = ref('');
const isStudentModalOpen = ref(false);

const specialProgramTalentPool: ComputedRef<ISpecialProgramTalentPool> = computed(
	() => store.state.specialProgramTalentPool.specialPoolTalentPool,
);

const talentPoolName = specialProgramTalentPool.value.name;
const studentList = specialProgramTalentPool.value.studentsList;

const viewStudent = (studentId: string) => {
	store.commit(MutationTypes.SET_STUDENT, { studentId });
	isStudentModalOpen.value = true;
};
const verifyStudent = async (item: any) => {
	const verified = item.isSpecialProgramVerified ?? false;
	const proceed = confirm(`Are you sure you want to ${verified ? 'unverify' : 'verify'} ${item.name}`);
	if (proceed) {
		await store.dispatch(StudentActionTypes.VERIFY_SPECIAL_PROGRAM_STUDENT, {
			studentId: item.alias,
			isSpecialProgramVerified: !verified,
			talentPoolName: talentPoolName,
		});
		alert(`${item.name} has been ${verified ? 'unverified' : 'verified'}`);
	}
};
const closeStudentModal = () => {
	isStudentModalOpen.value = false;
};
</script>
