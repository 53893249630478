<template>
	<v-row justify="center" align="center">
		<v-dialog v-model="dialogVisible" fullscreen transition="dialog-bottom-transition">
			<v-card class="p-0">
				<v-toolbar flat class="fixed-bar" color="#e66832">
					<v-toolbar-title>Document Review</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon @click="closeDialog">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-container class="d-flex flex-column ml-0 mr-0" fluid>
					<v-row>
						<!-- Document Viewer -->
						<v-col cols="9">
							<v-card class="document-card">
								<v-card-title class="document-title">
									{{ documents[currentIndex]?.name }}
								</v-card-title>
								<embed :src="documents[currentIndex]?.url" type="application/pdf" class="document-pdf" />
								<v-card-action>
									<div class="actions mt-4">
										<v-btn color="green" @click="approveDocument" :disabled="isProcessing">Approve</v-btn>
										<v-btn color="red" @click="openRejectDialog" :disabled="isProcessing">Reject</v-btn>
									</div>
								</v-card-action>
							</v-card>
						</v-col>
						<!-- Review Summary -->
						<v-col cols="3">
							<v-card class="review-card">
								<v-card-title>Review Summary</v-card-title>
								<v-divider></v-divider>
								<v-list shape>
									<v-list-item v-for="(doc, index) in documents" :key="doc.id" @click="currentIndex = index">
										<v-list-item-content>
											<v-list-item-title>{{ doc.name }}</v-list-item-title>
											<v-list-item-subtitle>
												{{ doc.isVerified ? '✅ Approved' : doc.shouldResubmit ? '❌ Rejected' : '⏳ Pending' }}
											</v-list-item-subtitle>
											<p v-if="doc.comments?.comment" class="rejection-comment mt-2">"{{ doc.comments.comment }}"</p>
										</v-list-item-content>
										<v-divider class="mt-3"></v-divider>
									</v-list-item>
								</v-list>
							</v-card>
							<div class="actions mt-4 justify-end">
								<v-btn v-if="allReviewed" color="primary" class="" @click="closeDialog">Complete Review</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<!-- Rejection Dialog -->
		<v-dialog v-model="showRejectDialog" max-width="500px">
			<v-card>
				<v-toolbar flat class="fixed-bar" color="#e66832">
					<v-toolbar-title>Rejection Comment</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon @click="closeDialog">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<v-text-field v-model="rejectionComment" label="Reason for rejection" required></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="closeRejectDialog">Cancel</v-btn>
					<v-btn text :disabled="!rejectionComment.trim()" @click="rejectDocument">Submit</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store/modules/bursary/action-types';

const props = defineProps({
	bursaryApplicationId: {
		type: String,
		required: true,
	},
	submittedDocuments: {
		type: Array,
		required: true,
	},
	openScreenDocuments: {
		type: Boolean,
		required: true,
	},
});

const emit = defineEmits(['update:openScreenDocuments', 'reviewCompleted']);

const currentIndex = ref(0);
const showRejectDialog = ref(false);
const rejectionComment = ref('');
const isProcessing = ref(false);
const store = useStore();

const documents = computed(() => [...props.submittedDocuments].sort((a, b) => a.name.localeCompare(b.name)));
const allReviewed = computed(() => documents.value.every(doc => doc.isVerified || doc.shouldResubmit));
const dialogVisible = computed(() => props.openScreenDocuments);

watch(
	() => props.submittedDocuments,
	newVal => {
		documents.value = [...newVal].sort((a, b) => a.name.localeCompare(b.name));
	},
	{ deep: true, immediate: true },
);

const approveDocument = async () => {
	isProcessing.value = true;
	const doc = documents.value[currentIndex.value];
	documents.value[currentIndex.value].isVerified = true;
	documents.value[currentIndex.value].shouldResubmit = false;
	documents.value[currentIndex.value].comments = {};
	await store.dispatch(ActionTypes.APPROVE_DOCUMENT, {
		bursaryApplicationId: props.bursaryApplicationId,
		documentId: doc.id,
		approved: true,
	});
	nextDocument();
	isProcessing.value = false;
};

const openRejectDialog = () => {
	showRejectDialog.value = true;
};

const closeRejectDialog = () => {
	showRejectDialog.value = false;
	rejectionComment.value = '';
};

const rejectDocument = async () => {
	if (!rejectionComment.value.trim()) return;
	isProcessing.value = true;
	const doc = documents.value[currentIndex.value];
	documents.value[currentIndex.value].isVerified = false;
	documents.value[currentIndex.value].shouldResubmit = true;
	const comments = {
		comment: rejectionComment.value,
		date: new Date().toISOString(),
	};
	documents.value[currentIndex.value].isVerified = false;
	documents.value[currentIndex.value].shouldResubmit = true;
	documents.value[currentIndex.value].comments = comments;
	await store.dispatch(ActionTypes.APPROVE_DOCUMENT, {
		bursaryApplicationId: props.bursaryApplicationId,
		documentId: doc.id,
		approved: false,
		comments,
	});
	closeRejectDialog();
	nextDocument();
	isProcessing.value = false;
};

const nextDocument = () => {
	if (currentIndex.value < documents.value.length - 1) {
		currentIndex.value++;
	}
};

const closeDialog = () => {
	emit('update:openScreenDocuments', false);
	emit('reviewCompleted', documents.value);
};
</script>

<style scoped>
.actions {
	display: flex;
	gap: 16px;
}

.document-title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
	text-align: center;
}

.document-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #f9f9f9;
	padding: 16px;
	border-radius: 8px;
	height: 80vh;
}

.review-card {
	display: flex;
	flex-direction: column;
	background: #f9f9f9;
	border-radius: 8px;
	height: 80vh;
}

.document-pdf {
	width: 100%;
	height: 100vh;
}

.rejection-comment {
	white-space: pre-wrap;
	word-wrap: break-word;
	overflow-wrap: break-word;
	color: red;
	font-weight: bold;
}

.fixed-bar {
	position: sticky;
	position: -webkit-sticky; /* for Safari */
	top: 0;
	z-index: 2;
}
</style>
