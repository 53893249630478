import { RouteRecordRaw } from 'vue-router';

import Students from '@/views/Dashboard/Students.vue';
import BaseLayout from '@/components/BaseLayout.vue';
import store from '@/store';
import { ActionTypes } from '@/store/modules/student/action-types';
import { ActionTypes as TalentPoolActionTypes } from '@/store/modules/talentPool/action-types';
import { ActionTypes as SpecialProgramActionTypes } from '@/store/modules/specialProgram/action-types';

export const StudentsRoute: RouteRecordRaw = {
	path: '/students',
	component: BaseLayout,
	children: [
		{
			path: '',
			name: 'students',
			component: Students,
			beforeEnter: async () => {
				await store.dispatch(ActionTypes.GET_STUDENT_LIST);
				await store.dispatch(TalentPoolActionTypes.GET_TALENT_POOL_LIST);
				// await store.dispatch(SpecialProgramActionTypes.GET_SPECIAL_PROGRAM_TALENT_POOL_LIST);
			},
		},
	],
};
