import { ActionContext, ActionTree } from 'vuex';
import Container from 'typedi';

import { ActionTypes } from './action-types';
import { State } from './state';
import { RootMutationTypes } from '../root/mutation-types';
import { RootState } from '../root/state';
import { MutationTypes } from './mutation-types';
import { SpecialProgramTalentPoolService } from '@/services/specialProgramTalentPool.service';
import { IStudentUser } from '@/interfaces/student.interface';

const specialPoolTalentPoolService = Container.get(SpecialProgramTalentPoolService);

export interface Actions {
	[ActionTypes.GET_SPECIAL_PROGRAM_TALENT_POOL_LIST]({ commit }: ActionContext<State, RootState>): void;
	[ActionTypes.CREATE_SPECIAL_PROGRAM_TALENT_POOL](
		{ commit }: ActionContext<State, RootState>,
		payload: { name: string; description: string },
	): void;
	[ActionTypes.EDIT_SPECIAL_PROGRAM_TALENT_POOL](
		{ commit }: ActionContext<State, RootState>,
		payload: { talentPoolId: string; name: string; description: string },
	): void;
	[ActionTypes.ADD_STUDENT_TO_SPECIAL_PROGRAM_TALENT_POOL](
		{ commit }: ActionContext<State, RootState>,
		payload: { talentPoolId: string; students: Array<IStudentUser> },
	): void;
	[ActionTypes.GET_SPECIAL_PROGRAM_TALENT_POOL](
		{ commit }: ActionContext<State, RootState>,
		payload: {
			specialProgramtalentPoolId: string;
		},
	): void;
	[ActionTypes.ASSIGN_SPECIAL_PROGRAM_TALENT_POOL_TO_EMPLOYER](
		{ commit }: ActionContext<State, RootState>,
		payload: { clientIds: Array<string>; talentId: string },
	): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.GET_SPECIAL_PROGRAM_TALENT_POOL_LIST]({ commit }) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const talentPoolList = await specialPoolTalentPoolService.getSpecialProgramTalentPools();
			commit(MutationTypes.SET_SPECIAL_PROGRAM_TALENT_POOL_LIST, talentPoolList);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with getting talent pools. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.CREATE_SPECIAL_PROGRAM_TALENT_POOL]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await specialPoolTalentPoolService.createSpecialProgramTalentPool(payload.name, payload.description);
			await dispatch(ActionTypes.GET_SPECIAL_PROGRAM_TALENT_POOL_LIST);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with creating a talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.EDIT_SPECIAL_PROGRAM_TALENT_POOL]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await specialPoolTalentPoolService.editSpecialProgramTalentPool(
				payload.talentPoolId,
				payload.name,
				payload.description,
			);
			await dispatch(ActionTypes.GET_SPECIAL_PROGRAM_TALENT_POOL_LIST);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with editing a talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.ADD_STUDENT_TO_SPECIAL_PROGRAM_TALENT_POOL]({ commit }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await specialPoolTalentPoolService.addStudentToTalentPool(payload.talentPoolId, payload.students);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with adding student to talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.GET_SPECIAL_PROGRAM_TALENT_POOL]({ commit }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const talentPool = await specialPoolTalentPoolService.getSpecialProgramTalentPool(
				payload.specialProgramtalentPoolId,
			);
			commit(MutationTypes.SET_SPECIAL_PROGRAM_TALENT_POOL, talentPool);
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with getting talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.ASSIGN_SPECIAL_PROGRAM_TALENT_POOL_TO_EMPLOYER]({ commit }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await specialPoolTalentPoolService.assignEmployertoTalentPool(payload.clientIds, payload.talentId);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problem with assigning employer(s) to talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
};
