import { ITalentPool, ITalentPoolStudent } from '@/interfaces/talentPool.interface';
import { DocumentData, DocumentSnapshot, QuerySnapshot, getDocs, collection } from 'firebase/firestore';
import { IPaginatedItems } from '@/interfaces/paginated.interface';

export const mapTalentPools = async function (
	talentPools: QuerySnapshot<DocumentData, DocumentData>,
): Promise<IPaginatedItems<ITalentPool>> {
	const talentPoolsWithStudents = await Promise.all(
		talentPools.docs.map(async (talentPoolSnap: DocumentData) => {
			const studentsRef = collection(talentPoolSnap.ref, 'talentPoolStudents');
			const studentsSnapshot = await getDocs(studentsRef);

			const students: ITalentPoolStudent[] = studentsSnapshot.docs.map(doc => {
				const data = doc.data();

				return {
					alias: data.alias ?? 'Unknown',
					email: data.email ?? 'N/A',
					name: data.name ?? 'N/A',
					surname: data.surname ?? 'N/A',
					userId: data.userId ?? 'N/A',
					docId: doc.id,
					phone: data.phone ?? 'N/A',
				};
			});
			const talentPool = talentPoolSnap.data();
			return {
				talentPoolId: talentPoolSnap.id,
				name: talentPool.name,
				description: talentPool.description,
				studentsList: students,
				studentNum: students?.length,
			};
		}),
	);

	return {
		currentPage: 1,
		previousPage: 0,
		itemsPerPage: talentPoolsWithStudents.length,
		totalPages: 1,
		totalItems: talentPoolsWithStudents.length,
		records: new Map(talentPoolsWithStudents.map((obj: ITalentPool) => [obj.talentPoolId, obj])),
	};
};

export const mapTalentPoolsForSingleDocument = async function (
	talentPools: DocumentSnapshot<DocumentData>,
): Promise<ITalentPool> {
	if (!talentPools.exists()) {
		throw new Error('Talent pool does not exist');
	}

	const studentsRef = collection(talentPools.ref, 'talentPoolStudents');
	const studentsSnapshot = await getDocs(studentsRef);

	const students: ITalentPoolStudent[] = studentsSnapshot.docs.map(doc => {
		const data = doc.data();
		return {
			alias: data.alias ?? 'Unknown',
			email: data.email ?? 'N/A',
			name: data.name ?? 'N/A',
			surname: data.surname ?? 'N/A',
			userId: data.userId ?? 'N/A',
			docId: doc.id,
			phone: data.phone ?? 'N/A',
		};
	});

	const talentPoolData = talentPools.data();

	return {
		talentPoolId: talentPools.id,
		name: talentPoolData?.name ?? 'Unknown',
		description: talentPoolData?.description ?? 'No description',
		studentNum: students?.length,
		studentsList: students ?? null,
	};
};
