<template>
	<div>
		<Suspense>
			<v-card flat>
				<v-card-title> Talent Pool: {{ talentPoolName }} </v-card-title>
				<template v-slot:text>
					<div class="d-flex flex-row justify-space-between">
						<v-text-field
							v-model="search"
							label="Search"
							prepend-inner-icon="mdi-magnify"
							variant="outlined"
							hide-details
							single-line
							class="mr-4"
							style="flex: 2"
						></v-text-field>
						<v-btn class="btn-download" prepend-icon="mdi-download" variant="outlined" @click="downloadCsv()">
							Download CSV
						</v-btn>
					</div>
				</template>
				<div>
					<v-data-table
						:headers="TALENT_POOL_STUDENTS"
						:search="search"
						:items="studentList"
						class="elevation-1"
						show-select
						v-model="selectedStudents"
						return-object
					>
						<template #[`item.actions`]="{ item }">
							<div style="text-align: right">
								<v-btn class="btn-jobox" prepend-icon="mdi-eye-outline" @click="viewStudent(item.alias)"
									>View Student</v-btn
								>
							</div>
						</template>
					</v-data-table>
				</div>
			</v-card>
		</Suspense>
		<StudentModal v-if="isStudentModalOpen" @close="closeStudentModal" />
	</div>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, ref } from 'vue';
import { useStore } from 'vuex';
import { ITalentPool } from '@/interfaces/talentPool.interface';
import { ITalentPoolStudent } from '@/interfaces/talentPool.interface';
import { IStudentUser } from '@/interfaces/student.interface';
import { TALENT_POOL_STUDENTS } from '@/constants/talent-pool.const';
import { MutationTypes } from '@/store/modules/student/mutation-types';
import StudentModal from '@/components/StudentModal.vue';
import { exportCsv } from '@/utils/file.util';
const store = useStore();
const search = ref('');
const isStudentModalOpen = ref(false);

const talentPool: ComputedRef<ITalentPool> = computed(() => store.state.talentPool.talentPool);

const studentMainList: ComputedRef<IStudentUser[]> = computed(() => [
	...store.state.student.studentList.records.values(),
]);

const talentPoolName = talentPool.value.name;
const studentList = talentPool.value.studentsList;

const viewStudent = (studentId: string) => {
	store.commit(MutationTypes.SET_STUDENT, { studentId });
	isStudentModalOpen.value = true;
};
const closeStudentModal = () => {
	isStudentModalOpen.value = false;
};

const selectedStudents = ref<ITalentPoolStudent[]>([]);

const downloadCsv = () => {
	const talentPoolStudents = selectedStudents.value.length === 0 ? studentList : selectedStudents.value;

	const rows = talentPoolStudents.map(applicant => {
		const student = studentMainList.value.find(student => student.alias === applicant.alias);
		return {
			FirstName: applicant.name,
			LastName: applicant.surname,
			Email: student ? student.email : '',
			PhoneNumber: student ? student.phone : '',
		};
	});

	exportCsv(rows, 'talentPoolStudents');
};
</script>
