import { RouteRecordRaw } from 'vue-router';

import Bursaries from '@/views/Dashboard/bursaries/ViewOpenBursaries.vue';
import BaseLayout from '@/components/BaseLayout.vue';
import store from '@/store';
import { ActionTypes } from '@/store/modules/bursary/action-types';
import ActiveBursaries from '@/views/Dashboard/bursaries/ActiveBursaries.vue';

export const BursaryRoute: RouteRecordRaw = {
	path: '/bursaries',
	component: BaseLayout,
	children: [
		{
			path: '',
			name: 'bursaries',
			component: Bursaries,
			beforeEnter: async () => {
				await store.dispatch(ActionTypes.GET_BURSARY_LIST);
			},
		},
		{
			path: '/bursaries/applicants/:bursaryId',
			name: 'active-bursary',
			component: ActiveBursaries,
		},
	],
};
