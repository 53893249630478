import { db } from '@/config/firebase.conf';
import { IBursary, IBursaryDocument, IStudentApplication } from '@/interfaces/bursary.interface';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { DocumentData, QuerySnapshot, where, getDocs, query, collection, limit } from 'firebase/firestore';

export const mapBursaries = async function (
	bursaries: QuerySnapshot<DocumentData, DocumentData>,
): Promise<IPaginatedItems<IBursary>> {
	const bursaryList = await Promise.all(
		bursaries.docs.map(async (bursarySnap: DocumentData) => {
			const bursary = bursarySnap.data();
			const employerRef = query(collection(db, 'clients'), where('userId', '==', bursary.employerId), limit(1));
			const employerSnap = await getDocs(employerRef);
			const employer = employerSnap.docs[0].data();

			return {
				bursaryId: bursarySnap.id,
				title: bursary.title,
				description: bursary.description,
				workPlacement: bursary.workPlacement,
				workPlacementInformation: bursary.workPlacementInformation,
				applicationDeadline: bursary.applicationDeadline,
				bursaryRequirements: bursary.bursaryRequirements,
				documentRequirements: bursary.documentRequirements,
				additionalDocuments: bursary.additionalDocuments,
				documentSubmissionInstructions: bursary.documentSubmissionInstructions,
				fundingCover: bursary.fundingCover,
				additionalCoverDetails: bursary.additionalCoverDetails,
				additionalSubmissions: bursary.additionalSubmissions,
				verified: bursary.verified,
				bursaryClosed: bursary.bursaryClosed,
				employerId: bursary.employerId,
				companyName: employer.companyName,
			};
		}),
	);

	return {
		currentPage: 1,
		previousPage: 0,
		itemsPerPage: bursaryList.length,
		totalPages: 1,
		totalItems: bursaryList.length,
		records: new Map(bursaryList.map((obj: IBursary) => [obj.bursaryId, obj])),
	};
};

export const mapStudentApplications = async function (
	applications: QuerySnapshot<DocumentData, DocumentData>,
): Promise<IPaginatedItems<IStudentApplication>> {
	const applicationList = await Promise.all(
		applications.docs.map(async (appSnap: DocumentData) => {
			const application = appSnap.data();
			const submittedDocuments: IBursaryDocument[] = [];

			Object.entries(application.submittedDocuments || {}).forEach(([key, value]: [string, any]) => {
				submittedDocuments.push({
					id: key,
					name: key,
					url: value.url,
					isVerified: value.isVerified,
					shouldResubmit: value.shouldResubmit,
					comments: value.comments,
				});
			});

			return {
				id: appSnap.id,
				alias: application.studentAlias,
				name: `${application.name ?? ''} ${application.surname ?? ''}`?.trim() ?? 'No Data Provided',
				applicantEmail: application.email,
				applicationStatus: application.applicationStatus,
				videoSubmissionUrl: application.videoSubmissionUrl,
				writtenSubmissionUrl: application.writtenSubmissionUrl,
				degree: application.selectedDegree?.degree ?? 'No Data Provided',
				yearOfStudy: application.selectedDegree?.yearOfStudy ?? 'No Data Provided',
				status: application.status,
				studentId: application.userId,
				resubmitEmailSent: application.resubmitEmailSent,
				submittedDocuments,
			};
		}),
	);

	return {
		currentPage: 1,
		previousPage: 0,
		itemsPerPage: applicationList.length,
		totalPages: 1,
		totalItems: applicationList.length,
		records: new Map(applicationList.map((obj: IStudentApplication) => [obj.alias, obj])),
	};
};
