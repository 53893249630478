import { Module } from 'vuex';

import { RootState } from '../root/state';
import { state, State } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

export const TalentPoolModule: Module<State, RootState> = {
	state,
	mutations,
	actions,
	getters,
};
