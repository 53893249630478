import { ActionContext, ActionTree } from 'vuex';
import Container from 'typedi';

import { ActionTypes } from './action-types';
import { State } from './state';
import { RootMutationTypes } from '../root/mutation-types';
import { RootState } from '../root/state';
import { MutationTypes } from './mutation-types';
import { StudentService } from '@/services/student.service';
import { SpecialProgramTalentPoolService } from '@/services/specialProgramTalentPool.service';
const studentService = Container.get(StudentService);
const specialPoolTalentPoolService = Container.get(SpecialProgramTalentPoolService);

export interface Actions {
	[ActionTypes.GET_STUDENT_LIST]({ commit }: ActionContext<State, RootState>, payload: { studentIds?: string[] }): void;
	[ActionTypes.VERIFY_SPECIAL_PROGRAM_STUDENT](
		{ commit }: ActionContext<State, RootState>,
		payload: { studentId: string; isSpecialProgramVerified: boolean; talentPoolName: string },
	): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.GET_STUDENT_LIST]({ commit }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const studentList = await studentService.getStudents(payload?.studentIds);
			commit(MutationTypes.SET_STUDENT_LIST, studentList);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			console.log(e);
			commit(RootMutationTypes.SET_ERROR_MSG, e?.message || 'Problems with getting students. Please try again later.');
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.VERIFY_SPECIAL_PROGRAM_STUDENT]({ commit }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await studentService.verifyStudentProgram(payload.studentId, payload.isSpecialProgramVerified);
			await specialPoolTalentPoolService.verifySpecialProgramStudent(
				payload.studentId,
				payload.isSpecialProgramVerified,
				payload.talentPoolName,
			);
		} catch (e: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, e?.message || 'Problems with verifying the job. Please try again later.');
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
};
