import { collection, doc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { Service } from 'typedi';
import { db } from '@/config/firebase.conf';
import { IBursary, IBursaryDocumentSubmission, IStudentApplication } from '@/interfaces/bursary.interface';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { mapBursaries, mapStudentApplications } from '@/mappers/bursary.mapper';
import { APPLICATION_STATUS } from '@/constants';

@Service()
export class BursaryService {
	/**
	 * @method getEmployers
	 * @async
	 * @returns {Promise<IPaginatedItems<IBursary>>}
	 */
	async getBursaries(): Promise<IPaginatedItems<IBursary>> {
		const bursarySnapshot = await getDocs(collection(db, 'bursaries'));

		return mapBursaries(bursarySnapshot);
	}

	/**
	 * @method getBursaryApplications
	 * @async
	 * @param {string} bursaryId
	 * @returns {Promise<IPaginatedItems<IStudentApplication>>}
	 */
	async getBursaryApplications(bursaryId: string): Promise<IPaginatedItems<IStudentApplication>> {
		const q = query(collection(db, 'bursary-applications'), where('bursaryID', '==', bursaryId));
		const applicationSnapshot = await getDocs(q);

		return mapStudentApplications(applicationSnapshot);
	}

	/**
	 * @method approveBursaryDoc
	 * @async
	 * @param {IBursaryDocumentSubmission} bursaryDoc
	 * @returns {Promise<void>}
	 */
	async approveBursaryDoc(bursaryDoc: IBursaryDocumentSubmission): Promise<void> {
		const { bursaryApplicationId, documentId, approved, comments } = bursaryDoc;
		try {
			const docRef = doc(db, 'bursary-applications', bursaryApplicationId);

			const updateData = {
				submittedDocuments: {
					[`${documentId}`]: {
						isVerified: approved,
						shouldResubmit: !approved,
						comments: comments ?? null,
					},
				},
				resubmitEmailSent: false,
			};

			await setDoc(docRef, updateData, { merge: true });
			console.log(`Document ${documentId} updated successfully.`);
		} catch (e: any) {
			console.error('Error updating document:', e.message || e);
		}
	}

	/**
	 * @method resubmitEmailSent
	 * @async
	 * @param {string} bursaryApplicationId
	 * @returns {Promise<void>}
	 */
	async resubmitEmailSent(bursaryApplicationId: string): Promise<void> {
		try {
			const docRef = doc(db, 'bursary-applications', bursaryApplicationId);
			await setDoc(docRef, { resubmitEmailSent: true }, { merge: true });
			console.log('Documentm updated successfully.');
		} catch (e: any) {
			console.error('Error updating document:', e.message || e);
		}
	}

	/**
	 * @method updateBursaryApplicantStatus
	 * @async
	 * @param {string} id
	 * @returns {Promise<void>}
	 */
	async updateBursaryApplicantStatus(id: string, status: APPLICATION_STATUS): Promise<void> {
		await updateDoc(doc(db, 'bursary-applications', id), {
			applicationStatus: status,
			status,
		});
	}
}
