<template>
	<div>
		<Suspense>
			<div>
				<h1 class="text-center">View Talent Pools</h1>
				<v-card title="Talent Pools" flat>
					<template v-slot:text>
						<div class="d-flex flex-row justify-space-between">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-inner-icon="mdi-magnify"
								variant="outlined"
								hide-details
								single-line
								class="mr-4"
								style="flex: 2"
							></v-text-field>
							<v-btn
								class="btn-jobox"
								prepend-icon="mdi-plus"
								text="Create Talent Pool"
								style="flex: 1"
								@click="createTalentPool"
							></v-btn>
						</div>
					</template>
					<div>
						<v-data-table
							:headers="TALENT_POOL_TABLE_HEADER"
							:search="search"
							:items="talentPoolList"
							class="elevation-1"
						>
							<template #[`item.actions`]="{ item }">
								<div style="text-align: right">
									<v-btn
										v-if="item.studentNum > 0"
										class="btn-jobox"
										prepend-icon="mdi-eye-outline"
										@click="viewTalentPool(item)"
										>View</v-btn
									>
									<v-menu>
										<template #activator="{ props }">
											<v-btn color="transparent" v-bind="props" flat> <v-icon>mdi-dots-vertical</v-icon> </v-btn>
										</template>
										<v-list>
											<v-list-item @click="editTalentPool(item)">
												<v-list-item-title>Edit Talent Pool Details</v-list-item-title>
											</v-list-item>
											<v-list-item @click="employerAccess(item)">
												<v-list-item-title>Employer Access</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>
							</template>
						</v-data-table>
					</div>
				</v-card>
			</div>
		</Suspense>
		<TalentPoolCreateModal
			v-if="isTalentPoolOpen"
			:openTalentPool="isTalentPoolOpen"
			:isEditTalentPool="isEditTalentPool"
			@close="closeTalentModal"
			@closeTalentPool="closeTalentModal"
			:currentTalentPool="currentTalentPool"
		/>

		<EmployerModal
			v-if="isEmployerModalOpen"
			:employers="employerList"
			:openEmployerModal="isEmployerModalOpen"
			@closeEmployerModal="closeEmployerModal"
			:currentTalentPool="currentTalentPool"
			@fetchEmployers="fetchEmployers"
		/>
	</div>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect, ComputedRef } from 'vue';
import TalentPoolCreateModal from '@/components/TalentPoolCreateModal.vue';
import EmployerModal from '@/components/EmployerModal.vue';
import { MutationTypes } from '@/store/modules/talentPool/mutation-types';
import { ITalentPool } from '@/interfaces/talentPool.interface';
import { TALENT_POOL_TABLE_HEADER } from '@/constants/talent-pool.const';
import { useStore } from 'vuex';
import router from '@/router';
import { ICompany } from '@/interfaces/employer.interface';
import { ActionTypes } from '@/store/modules/employer/action-types';
const search = ref('');
const isEmployerModalOpen = ref(false);
const isTalentPoolOpen = ref(false);
const isEditTalentPool = ref(false);
const currentTalentPool = ref({});
const isRemoveTalentPool = ref(false);
const pageNumber = ref(1);
const pageSize = ref(10);
const store = useStore();
const talentPoolList: ComputedRef<ITalentPool[]> = computed(() => [
	...store.state.talentPool.talentPoolList.records.values(),
]);

const employerList: ComputedRef<ICompany[]> = computed(() => store.state.employer.employerList);

const closeTalentModal = () => {
	isTalentPoolOpen.value = false;
	isEditTalentPool.value = false;
	currentTalentPool.value = {};
};
const createTalentPool = () => {
	isTalentPoolOpen.value = true;
};
const editTalentPool = (item: string) => {
	isTalentPoolOpen.value = true;
	isEditTalentPool.value = true;
	currentTalentPool.value = item;
};

const employerAccess = (item: string) => {
	isEmployerModalOpen.value = true;
	currentTalentPool.value = item;
};
const closeEmployerModal = () => {
	isEmployerModalOpen.value = false;
	isRemoveTalentPool.value = false;
};
const viewTalentPool = (item: string) => {
	router.push({ name: 'current-talent-pool', params: { talentPoolId: item.talentPoolId } });
};
const fetchEmployers = async () => {
	await store.dispatch(ActionTypes.GET_EMPLOYERS);
};
</script>
