import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { rootState, RootState } from '../root/state';
import { ISpecialProgramTalentPool } from '@/interfaces/specialProgramTalentPool.interface';

export const state = {
	...rootState,
	specialProgramTalentPoolList: <IPaginatedItems<ISpecialProgramTalentPool>>{
		currentPage: 0,
		previousPage: 0,
		itemsPerPage: 0,
		totalPages: 0,
		totalItems: 0,
		records: new Map(),
	},
	specialPoolTalentPool: <ISpecialProgramTalentPool | undefined>{},
};

export type State = typeof state & RootState;
