<template>
	<Suspense>
		<div class="table-container">
			<h1 class="text-center">View Open Bursaries</h1>
			<div class="justify-end">
				<v-btn class="btn-filter" prepend-icon="mdi-filter-outline" variant="outlined" @click="toggleFilterMenu()"
					>Filter</v-btn
				>
			</div>
			<v-card title="View Open Bursaries" flat>
				<template v-slot:text>
					<v-text-field
						v-model="search"
						label="Search"
						prepend-inner-icon="mdi-magnify"
						variant="outlined"
						hide-details
						single-line
					>
					</v-text-field>
				</template>

				<v-data-table
					:headers="BURSARY_TABLE_HEADER"
					:items="filteredBursaries"
					:search="search"
					:loading="loading"
					class="elevation-1"
				>
					<template #[`item.actions`]="{ item }">
						<v-btn class="btn-jobox" prepend-icon="mdi-eye-outline" @click="() => viewBursaries(item)">View</v-btn>
					</template>
				</v-data-table>
			</v-card>
			<BursaryFilter
				:isFilterClicked="isFilterClicked"
				@update:companyNames="handleCompanyNameChange"
				@update:isClosed="handleFilterClose"
				@update:status="handleStatusChange"
				:bursaries="bursaryList"
			/>
		</div>
	</Suspense>
</template>

<script lang="ts" setup>
import { ref, computed, Ref, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { BURSARY_TABLE_HEADER } from '@/constants/table-headers.const';
import { IBursary } from '@/interfaces/bursary.interface';
import BursaryFilter from '@/components/Filters/BursaryFilter.vue';
import router from '@/router';
import { MutationTypes } from '@/store/modules/bursary/mutation-types';

const search: Ref<string> = ref('');
const isFilterClicked: Ref<boolean> = ref(false);
const companyNames: Ref<string[]> = ref([]);
const statuses: Ref<boolean[]> = ref([]);
const store = useStore();

const loading: ComputedRef<boolean> = computed(() => store.state.root.LOADING);
const bursaryList: ComputedRef<IBursary[]> = computed(() => [...store.state.bursary.bursaryList.records.values()]);
const toggleFilterMenu = () => {
	isFilterClicked.value = !isFilterClicked.value;
};

const viewBursaries = (item: IBursary) => {
	store.commit(MutationTypes.SET_BURSARY, { bursaryId: item.bursaryId });
	router.push({ name: 'active-bursary', params: { bursaryId: item.bursaryId } });
};

const handleCompanyNameChange = (companyList: string[]) => {
	companyNames.value = companyList;
};
const handleStatusChange = (status: string[]) => {
	statuses.value = status.map(str => str === 'True');
};
const handleFilterClose = (filterClose = false) => {
	isFilterClicked.value = filterClose;
};

const filteredBursaries: ComputedRef<IBursary[]> = computed(() => {
	return (
		bursaryList.value?.filter(
			(bursary: IBursary) =>
				(companyNames.value.length === 0 || companyNames.value.includes(bursary.companyName)) &&
				(statuses.value.length === 0 || statuses.value.includes(bursary.verified)),
		) || []
	);
});
</script>
