<template>
	<v-dialog v-model="openTalentPool" max-width="700px">
		<v-card>
			<v-toolbar flat class="fixed-bar" color="#e66832">
				<v-toolbar-title>
					<template v-if="isEditTalentPool">Edit Talent Pool</template>
					<template v-else>Create Talent Pool</template>
				</v-toolbar-title>

				<v-spacer></v-spacer>
				<v-btn icon @click="closeTalentPool">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-checkbox
				v-if="isStudentSideCreate"
				v-model="isSpecialProgramCreate"
				label="Create Special Program Talent Pool"
			></v-checkbox>
			<v-card-item>
				<v-text-field
					:hint="talentPoolHint"
					:label="talentPoolLabel"
					type="input"
					clearable
					clear-icon="mdi-close-circle"
					v-model="talentPoolName"
					:error-messages="talentPoolNameError"
				></v-text-field>
			</v-card-item>
			<v-card-item>
				<v-textarea
					:hint="talentPoolDescriptionHint"
					:label="talentPoolDescriptionLabel"
					type="input"
					clearable
					clear-icon="mdi-close-circle"
					v-model="talentPoolDescription"
					:error-messages="talentPoolDescriptionError"
				></v-textarea>
			</v-card-item>
			<v-card-actions class="d-flex flex-row justify-start">
				<v-btn v-if="!isEditTalentPool" class="btn-jobox" text="Submit" @click="createTalentPool"></v-btn>
				<v-btn v-if="isEditTalentPool" class="btn-jobox" text="Edit Talent Pool" @click="editTalentPool"></v-btn>
				<v-btn text="Cancel" @click="closeTalentPool"></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, Ref, ref, watch } from 'vue';
import { ActionTypes } from '@/store/modules/talentPool/action-types';
import { ActionTypes as SpecialProgramActionTypes } from '@/store/modules/specialProgram/action-types';
import { useStore } from 'vuex';

const props = defineProps({
	isStudentSideCreate: {
		type: Boolean,
		default: false,
	},
	openTalentPool: {
		type: Boolean,
		default: false,
	},
	isEditTalentPool: {
		type: Boolean,
		default: false,
	},
	currentTalentPool: {
		type: Object,
		default: null,
	},
});
const talentPoolNameError = ref('');
const talentPoolDescriptionError = ref('');
const isSpecialProgramCreate = ref(false);
const isStudentSideCreate = computed(() => props.isStudentSideCreate);
const openTalentPool = computed(() => props.openTalentPool);
const talentPoolDescription: Ref<string> = ref('');
const talentPoolName: Ref<string> = ref('');
const talentPoolId: Ref<string> = ref('');
const store = useStore();
const talentPoolHint = computed(() =>
	isSpecialProgramCreate.value
		? 'Enter the name of the special program talent pool'
		: 'Enter the name of the talent pool',
);
const talentPoolLabel = computed(() =>
	isSpecialProgramCreate.value ? 'Special Program Talent Pool Name' : 'Talent Pool Name',
);
const talentPoolDescriptionHint = computed(() =>
	isSpecialProgramCreate.value
		? 'Enter the name of the special program talent pool'
		: 'Enter the name of the talent pool',
);
const talentPoolDescriptionLabel = computed(() =>
	isSpecialProgramCreate.value ? 'Special Program Talent Pool Description' : 'Talent Pool Description',
);

const emit = defineEmits(['closeTalentPool']);
watch(
	() => props.currentTalentPool,
	newValue => {
		if (props.isEditTalentPool && newValue) {
			talentPoolName.value = newValue.name || '';
			talentPoolDescription.value = newValue.description || '';
			talentPoolId.value = newValue.talentPoolId;
		} else {
			talentPoolName.value = '';
			talentPoolDescription.value = '';
		}
	},
	{ immediate: true },
);

const closeTalentPool = () => {
	emit('closeTalentPool', false);
};
const createTalentPool = async () => {
	if (validateFields()) {
		const createTalentPool = isSpecialProgramCreate.value
			? SpecialProgramActionTypes.CREATE_SPECIAL_PROGRAM_TALENT_POOL
			: ActionTypes.CREATE_TALENT_POOL;

		await store.dispatch(createTalentPool, {
			name: talentPoolName.value,
			description: talentPoolDescription.value,
		});
		alert(`Talent Pool ${talentPoolName.value} has been created.`);
		emit('closeTalentPool');
	}
};
const editTalentPool = async () => {
	await store.dispatch(ActionTypes.EDIT_TALENT_POOL, {
		talentPoolId: talentPoolId.value,
		name: talentPoolName.value,
		description: talentPoolDescription.value,
	});
	emit('closeTalentPool');
	alert(`Successful edit for  ${talentPoolName.value} `);
};
const validateFields = () => {
	let isValid = true;

	if (!talentPoolName.value.trim()) {
		talentPoolNameError.value = isSpecialProgramCreate.value
			? ' Special Program Talent Pool Name is required'
			: 'Talent Pool Name is required';
		isValid = false;
	} else {
		talentPoolNameError.value = '';
	}

	if (!talentPoolDescription.value.trim()) {
		talentPoolDescriptionError.value = isSpecialProgramCreate.value
			? 'Special Program Talent Pool Description is required'
			: 'Talent Pool Description is required';
		isValid = false;
	} else {
		talentPoolDescriptionError.value = '';
	}

	return isValid;
};
</script>
