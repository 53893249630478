import { collection, getDocs, getDoc, doc, setDoc, updateDoc, writeBatch, query, where } from 'firebase/firestore';
import { Service } from 'typedi';
import {
	mapSpecialProgramTalentPools,
	mapSpecialProgramTalentPoolsForSingleDocument,
} from '@/mappers/specialProgramTalentPool.mapper';
import { db } from '@/config/firebase.conf';
import { ISpecialProgramTalentPool } from '@/interfaces/specialProgramTalentPool.interface';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import slugify from 'slugify';
import { IStudentUser } from '@/interfaces/student.interface';

@Service()
export class SpecialProgramTalentPoolService {
	/**
	 * @method getSpecialProgramTalentPools
	 * @async
	 * @returns {Promise<IPaginatedItems<ISpecialProgramTalentPool>>} return promise of talent pools
	 */
	async getSpecialProgramTalentPools(): Promise<IPaginatedItems<ISpecialProgramTalentPool>> {
		const specialProgramtalentPoolSnapshot = await getDocs(collection(db, 'specialProgramTalentPools'));
		return mapSpecialProgramTalentPools(specialProgramtalentPoolSnapshot);
	}

	/**
	 * @method createSpecialProgramTalentPool
	 * @async
	 * @param {string} name
	 * @param {string} description
	 */
	async createSpecialProgramTalentPool(name: string, description: string): Promise<void> {
		const poolId = slugify(name + ' ' + Date.now(), {
			replacement: '-',
			remove: /[$*_+~.()'"!\-:@]/g,
			lower: true,
		});

		await setDoc(doc(db, 'specialProgramTalentPools', poolId), {
			name: name,
			description: description,
			createdAt: new Date(),
		});
	}

	/**
	 * @method editSpecialProgramTalentPool
	 * @async
	 * @param {string} talentPoolId
	 * @param {string} name
	 * @param {string} description
	 */
	async editSpecialProgramTalentPool(talentPoolId: string, name: string, description: string): Promise<void> {
		const talentPoolRef = doc(db, 'specialProgramTalentPools', talentPoolId);
		await updateDoc(talentPoolRef, {
			name: name,
			description: description,
		});
	}

	/**
	 * @method addStudentToTalentPool
	 * @async
	 * @param {string} talentPoolId
	 * @param {object} student
	 *
	 */
	async addStudentToTalentPool(talentPoolId: string, students: Array<IStudentUser>): Promise<void> {
		const talentPoolRef = doc(db, 'specialProgramTalentPools', talentPoolId);
		const studentsSubcollectionRef = collection(talentPoolRef, 'specialProgramTalentPoolStudents');

		const talentPoolSnap = await getDoc(talentPoolRef);
		if (!talentPoolSnap.exists()) {
			throw new Error('Talent pool not found');
		}

		const batch = writeBatch(db);
		for (const student of students) {
			const studentData = {
				name: student.name,
				surname: student.surname,
				userId: student.userId,
				email: student.email,
				alias: student.alias,
				isSpecialProgramVerified: false,
			};

			const studentDocRef = doc(studentsSubcollectionRef, student.userId);
			batch.set(studentDocRef, studentData, { merge: true });
		}

		// Commit batch write
		await batch.commit();
	}

	/**
	 * @method assignEmployertoTalentPool
	 * @async
	 * @param {Array<string>} clientIds
	 * @param {string} talentId
	 *
	 */
	async assignEmployertoTalentPool(clientIds: Array<string>, talentId: string): Promise<void> {
		for (const clientId of clientIds) {
			const clientQuery = query(collection(db, 'clients'), where('userId', '==', clientId));
			const clientSnapshot = await getDocs(clientQuery);

			if (clientSnapshot.empty) {
				console.error(`No client found with userId: ${clientId}`);
				continue;
			}

			const clientDoc = clientSnapshot.docs[0];
			const clientRef = clientDoc.ref;

			const talentPoolsSubcollectionRef = collection(clientRef, 'specialProgramTalentPools');
			const existingTalentPoolsSnapshot = await getDocs(talentPoolsSubcollectionRef);
			const existingTalentPoolIds = existingTalentPoolsSnapshot.docs.map(doc => doc.id);
			if (existingTalentPoolIds.includes(talentId)) {
				continue;
			}

			const talentPoolDocRef = doc(talentPoolsSubcollectionRef, talentId);
			await setDoc(talentPoolDocRef, { talentPoolId: talentId, assignedAt: new Date().toISOString() }, { merge: true });
		}
	}

	/**
	 * @method getSpecialProgramTalentPool
	 * @async
	 * @param {string} specialProgramtalentPoolId
	 * @returns {Promise<ISpecialProgramTalentPool>}
	 */
	async getSpecialProgramTalentPool(specialProgramtalentPoolId: string): Promise<ISpecialProgramTalentPool> {
		const talentPoolRef = doc(db, 'specialProgramTalentPools', specialProgramtalentPoolId);
		const talentPoolSnap = await getDoc(talentPoolRef);
		const talentPool = mapSpecialProgramTalentPoolsForSingleDocument(talentPoolSnap);
		return talentPool;
	}

	/**
	 * @method verifySpecialProgramStudent
	 * @async
	 * @param {string} studentId
	 * @param {boolean} isSpecialProgramVerified
	 * @param {string} talentPoolName
	 */
	async verifySpecialProgramStudent(
		studentId: string,
		isSpecialProgramVerified: boolean,
		talentPoolName: string,
	): Promise<void> {
		try {
			const specialProgramTalentPoolsRef = collection(db, 'specialProgramTalentPools');
			const talentPoolQuery = query(specialProgramTalentPoolsRef, where('name', '==', talentPoolName));
			const talentPoolSnapshot = await getDocs(talentPoolQuery);

			if (talentPoolSnapshot.empty) {
				return;
			}
			const talentPoolDoc = talentPoolSnapshot.docs[0];
			const studentsRef = collection(talentPoolDoc.ref, 'specialProgramTalentPoolStudents');
			const studentQuery = query(studentsRef, where('alias', '==', studentId));
			const studentSnapshot = await getDocs(studentQuery);
			if (studentSnapshot.empty) {
				console.warn(`No student found with userId: ${studentId} in talent pool: ${talentPoolName}`);
				return;
			}
			const studentDocRef = studentSnapshot.docs[0].ref;
			await updateDoc(studentDocRef, { isSpecialProgramVerified: isSpecialProgramVerified });
		} catch (error) {
			console.error('Error updating special program verification:', error);
			throw new Error('Failed to update special program verification. Please try again.');
		}
	}
}
