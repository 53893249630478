import { RouteRecordRaw } from 'vue-router';

import Jobs from '@/views/Dashboard/jobs/Jobs.vue';
import ActiveJob from '@/views/Dashboard/jobs/active/ActiveJob.vue';
import BaseLayout from '@/components/BaseLayout.vue';
import EditJobWizard from '@/views/Dashboard/jobs/micro/edit/EditJobWizard.vue';
import store from '@/store';
import { ActionTypes } from '@/store/modules/job/action-types';
import { ActionTypes as TalentPoolActionTypes } from '@/store/modules/talentPool/action-types';
import { ActionTypes as SpecialProgramActionTypes } from '@/store/modules/specialProgram/action-types';

export const JobsRoute: RouteRecordRaw = {
	path: '/jobs',
	component: BaseLayout,
	children: [
		{
			path: '',
			name: 'jobs',
			component: Jobs,
			beforeEnter: async () => {
				await store.dispatch(ActionTypes.GET_JOB_LIST);
			},
		},
		{
			path: '/active/applicants/:jobId',
			name: 'active-job',
			component: ActiveJob,
			beforeEnter: async () => {
				await store.dispatch(TalentPoolActionTypes.GET_TALENT_POOL_LIST);
				//await store.dispatch(SpecialProgramActionTypes.GET_SPECIAL_PROGRAM_TALENT_POOL_LIST);
			},
		},
		{
			path: '/jobs/micro/edit/:jobId',
			name: 'edit-job',
			component: EditJobWizard,
		},
	],
};
