<template>
	<Suspense>
		<div>
			<v-card style="position: relative">
				<v-card-title class="text-center justify-center py-6">
					<h1 class="font-weight-bold text-h4">{{ bursary.title }}</h1>
				</v-card-title>

				<v-card-item>
					<v-tabs v-model="tab" grow>
						<v-tab v-for="item in tabItems" :key="item" :value="item" :class="{ 'active-tab': tab === item }">
							{{ item }}
						</v-tab>
					</v-tabs>

					<v-window v-model="tab">
						<v-window-item v-for="item in tabItems" :key="item" :value="item">
							<div v-if="item === 'Bursary Applicants'">
								<v-card flat style="height: calc(100vh - 290px); overflow-y: auto">
									<ApplicantList />
								</v-card>
							</div>

							<div v-if="item === 'Shortlist'">
								<v-card flat style="height: calc(100vh - 290px); overflow-y: auto">
									<ApplicantList />
								</v-card>
							</div>
						</v-window-item>
					</v-window>
				</v-card-item>
			</v-card>
		</div>
	</Suspense>
</template>

<script lang="ts" setup>
import { Ref, ref, computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import ApplicantList from '@/views/Dashboard/bursaries/ApplicantList.vue';
import { IBursary } from '@/interfaces/bursary.interface';
const tab: Ref<string> = ref('BursaryApplicants');
const tabItems: Ref<string[]> = ref(['Bursary Applicants', 'Shortlist']);
const store = useStore();

const bursary: ComputedRef<IBursary> = computed(() => store.state.bursary.bursary);
</script>
