import { GetterTree } from 'vuex';

import { State } from './state';
import { RootState } from '../root/state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { ISpecialProgramTalentPool } from '@/interfaces/specialProgramTalentPool.interface';

export type Getters = {
	getSpecialProgramTalentPools(state: State): IPaginatedItems<ISpecialProgramTalentPool>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	getSpecialProgramTalentPools: state => {
		return state.specialProgramTalentPoolList;
	},
};
