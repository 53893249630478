import { MutationTree } from 'vuex';

import { MutationTypes } from './mutation-types';
import { State } from './state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { ITalentPool } from '@/interfaces/talentPool.interface';

export type Mutations<S = State> = {
	[MutationTypes.SET_TALENT_POOL_LIST](state: S, payload: IPaginatedItems<ITalentPool>): void;
	[MutationTypes.SET_TALENT_POOL](state: S, payload: ITalentPool): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_TALENT_POOL_LIST](state: State, payload: IPaginatedItems<ITalentPool>) {
		state.talentPoolList = payload;
	},
	[MutationTypes.SET_TALENT_POOL](state: State, payload: ITalentPool) {
		state.talentPool = payload;
	},
};
