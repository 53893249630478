export const TALENT_POOL_TABLE_HEADER = [
	{ key: 'name', title: 'Name', align: 'start', sortable: true },
	{ key: 'description', title: 'Description' },
	{ key: 'studentNum', title: 'No. Students' },
	{ key: 'actions', title: 'Actions', align: 'end', sortable: false },
];

export const TALENT_POOL_STUDENTS = [
	{ key: 'name', title: 'Name', align: 'start', sortable: true },
	{ key: 'surname', title: 'Surname' },
	{ key: 'email', title: 'Email' },
	{ key: 'actions', title: 'Actions', align: 'end', sortable: false },
];

export const SPECIAL_PROGRAM_TALENT_POOL_STUDENTS = [
	{ key: 'name', title: 'Name', align: 'start', sortable: true },
	{ key: 'surname', title: 'Surname' },
	{ key: 'email', title: 'Email' },
	{ key: 'isSpecialProgramVerified', title: 'Verified Status' },
	{ key: 'actions', title: 'Actions', align: 'end', sortable: false },
];
