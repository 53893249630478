import { ActionContext, ActionTree } from 'vuex';
import Container from 'typedi';

import { ActionTypes } from './action-types';
import { State } from './state';
import { RootMutationTypes } from '../root/mutation-types';
import { RootState } from '../root/state';
import { MutationTypes } from './mutation-types';
import { TalentPoolService } from '@/services/talentPool.service';
import { IStudentUser } from '@/interfaces/student.interface';

const talentPoolService = Container.get(TalentPoolService);

export interface Actions {
	[ActionTypes.GET_TALENT_POOL_LIST]({ commit }: ActionContext<State, RootState>): void;
	[ActionTypes.CREATE_TALENT_POOL](
		{ commit }: ActionContext<State, RootState>,
		payload: { name: string; description: string },
	): void;
	[ActionTypes.EDIT_TALENT_POOL](
		{ commit }: ActionContext<State, RootState>,
		payload: { talentPoolId: string; name: string; description: string },
	): void;
	[ActionTypes.ADD_STUDENT_TO_TALENT_POOL](
		{ commit }: ActionContext<State, RootState>,
		payload: { talentPoolId: string; students: Array<IStudentUser> },
	): void;
	[ActionTypes.GET_TALENT_POOL]({ commit }: ActionContext<State, RootState>, payload: { talentPoolId: string }): void;
	[ActionTypes.ASSIGN_TALENT_POOL_TO_EMPLOYER](
		{ commit }: ActionContext<State, RootState>,
		payload: { clientIds: Array<string>; talentId: string },
	): void;
	[ActionTypes.REMOVE_TALENT_POOL_ACCESS_FROM_EMPLOYER](
		{ commit }: ActionContext<State, RootState>,
		payload: { clientIds: Array<string>; talentId: string },
	): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.GET_TALENT_POOL_LIST]({ commit }) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const talentPoolList = await talentPoolService.getTalentPools();
			commit(MutationTypes.SET_TALENT_POOL_LIST, talentPoolList);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with getting talent pools. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.CREATE_TALENT_POOL]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await talentPoolService.createTalentPool(payload.name, payload.description);
			await dispatch(ActionTypes.GET_TALENT_POOL_LIST);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with creating a talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.EDIT_TALENT_POOL]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await talentPoolService.editTalentPool(payload.talentPoolId, payload.name, payload.description);
			await dispatch(ActionTypes.GET_TALENT_POOL_LIST);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with editing a talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.ADD_STUDENT_TO_TALENT_POOL]({ commit }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await talentPoolService.addStudentToTalentPool(payload.talentPoolId, payload.students);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with adding student to talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.GET_TALENT_POOL]({ commit }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const talentPool = await talentPoolService.getTalentPool(payload.talentPoolId);
			commit(MutationTypes.SET_TALENT_POOL, talentPool);
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with getting talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.ASSIGN_TALENT_POOL_TO_EMPLOYER]({ commit }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await talentPoolService.assignEmployertoTalentPool(payload.clientIds, payload.talentId);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problem with assigning employer(s) to talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.REMOVE_TALENT_POOL_ACCESS_FROM_EMPLOYER]({ commit }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await talentPoolService.removeEmployerAccessToTalentPool(payload.clientIds, payload.talentId);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problem with assigning employer(s) to talent pool. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
};
