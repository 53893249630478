<template>
	<Suspense>
		<div class="sidebar-content-container">
			<div class="sidebar-bg"></div>
			<div class="img-container">
				<h4>Welcome, {{ displayName }}</h4>
			</div>

			<div class="menu-container">
				<div class="menu-link">
					<router-link :to="{ name: 'jobs' }"><v-icon class="icon">mdi-briefcase</v-icon> Jobs</router-link>
				</div>
				<div class="menu-link">
					<router-link :to="{ name: 'students' }"
						><v-icon class="icon">mdi-account-school</v-icon> Students</router-link
					>
				</div>
				<div class="menu-link">
					<router-link :to="{ name: 'bursaries' }"><v-icon class="icon">mdi-file</v-icon> Bursaries</router-link>
				</div>
				<div class="menu-link">
					<router-link :to="{ name: 'talent-pool' }"
						><v-icon class="icon">mdi-account-school</v-icon> Talent Pools</router-link
					>
				</div>
				<!-- <div class="menu-link">
					<router-link :to="{ name: 'special-program' }"
						><v-icon class="icon">mdi-account-school</v-icon> Special Programs</router-link
					>
				</div> -->
			</div>
		</div>
	</Suspense>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const displayName: ComputedRef<string> = computed(() => {
	const user = ref(store.state.auth.joboxUser);
	return user?.value?.displayName || 'Jobox User';
});
</script>

<style lang="scss" scoped>
.sidebar-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-image: url('@/assets/sidebar-2.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.sidebar-content-container {
	width: 300px;
	height: 100vh;

	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	position: relative;
	.img-container {
		width: 100%;
		padding: 2rem;
		text-align: center;
		border-bottom: 1px solid #f2f2f2;
	}

	.menu-container {
		.menu-link {
			width: 100%;
			display: flex;
			align-items: center;

			padding: 1rem;

			.icon {
				color: white;
				margin-right: 1rem;
			}

			a {
				text-decoration: none;
				color: white;
				width: 100%;
				text-align: left;
				padding: 0.5rem;
				&.router-link-exact-active {
					background-color: #e66832;
					color: white;
					width: 100%;
					text-align: left;
					border-radius: 5px;
					margin: 0;
				}
				&.router-link-active {
					background-color: #e66832;
					color: white;
					width: 100%;
					text-align: left;
					border-radius: 5px;
					margin: 0;
				}

				&:hover {
					background-color: rgba(86, 86, 86, 0.5);
					border-radius: 5px;
					transition: 0.1s ease-out;
				}
			}
		}
	}
}
</style>
