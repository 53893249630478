import {
	collection,
	getDocs,
	getDoc,
	doc,
	setDoc,
	updateDoc,
	writeBatch,
	query,
	where,
	deleteDoc,
} from 'firebase/firestore';
import { Service } from 'typedi';
import { mapTalentPools, mapTalentPoolsForSingleDocument } from '@/mappers/talentPool.mapper';
import { db } from '@/config/firebase.conf';
import { ITalentPool } from '@/interfaces/talentPool.interface';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import slugify from 'slugify';
import { IStudentUser } from '@/interfaces/student.interface';

@Service()
export class TalentPoolService {
	/**
	 * @method getTalentPools
	 * @async
	 * @returns {Promise<IPaginatedItems<ITalentPool>>}
	 */
	async getTalentPools(): Promise<IPaginatedItems<ITalentPool>> {
		const talentPoolSnapshot = await getDocs(collection(db, 'talentPools'));
		return mapTalentPools(talentPoolSnapshot);
	}

	/**
	 * @method createTalentPool
	 * @async
	 * @param {string} name
	 * @param {string} description
	 */
	async createTalentPool(name: string, description: string): Promise<void> {
		const poolId = slugify(name + ' ' + Date.now(), {
			replacement: '-',
			remove: /[$*_+~.()'"!\-:@]/g,
			lower: true,
		});

		await setDoc(doc(db, 'talentPools', poolId), {
			name: name,
			description: description,
			createdAt: new Date(),
		});
	}

	/**
	 * @method editTalentPool
	 * @async
	 * @param {string} talentPoolId
	 * @param {string} name
	 * @param {string} description
	 */
	async editTalentPool(talentPoolId: string, name: string, description: string): Promise<void> {
		const talentPoolRef = doc(db, 'talentPools', talentPoolId);
		await updateDoc(talentPoolRef, {
			name: name,
			description: description,
		});
	}

	/**
	 * @method addStudentToTalentPool
	 * @async
	 * @param {string} talentPoolId
	 * @param {object} student
	 *
	 */
	async addStudentToTalentPool(talentPoolId: string, students: Array<IStudentUser>): Promise<void> {
		const talentPoolRef = doc(db, 'talentPools', talentPoolId);
		const studentsSubcollectionRef = collection(talentPoolRef, 'talentPoolStudents');
		const talentPoolSnap = await getDoc(talentPoolRef);
		if (!talentPoolSnap.exists()) {
			throw new Error('Talent pool not found');
		}
		const batch = writeBatch(db);

		for (const student of students) {
			const studentData = {
				name: student.name,
				surname: student.surname,
				userId: student.userId,
				email: student.email,
				alias: student.alias,
				phone: student.phone,
			};

			const studentDocRef = doc(studentsSubcollectionRef, student.userId);
			batch.set(studentDocRef, studentData, { merge: true });
		}

		await batch.commit();
	}

	/**
	 * @method assignEmployertoTalentPool
	 * @async
	 * @param {Array<string>} clientIds
	 * @param {string} talentId
	 *
	 */
	async assignEmployertoTalentPool(clientIds: Array<string>, talentId: string): Promise<void> {
		for (const clientId of clientIds) {
			const clientQuery = query(collection(db, 'clients'), where('userId', '==', clientId));
			const clientSnapshot = await getDocs(clientQuery);

			if (clientSnapshot.empty) {
				console.error(`No client found with userId: ${clientId}`);
				continue;
			}

			const clientDoc = clientSnapshot.docs[0];
			const clientRef = clientDoc.ref;
			const talentPoolsSubcollectionRef = collection(clientRef, 'talentPools');
			const existingTalentPoolsSnapshot = await getDocs(talentPoolsSubcollectionRef);
			const existingTalentPoolIds = existingTalentPoolsSnapshot.docs.map(doc => doc.id);
			if (existingTalentPoolIds.includes(talentId)) {
				continue;
			}

			const talentPoolDocRef = doc(talentPoolsSubcollectionRef, talentId);

			await setDoc(talentPoolDocRef, { talentPoolId: talentId, assignedAt: new Date().toISOString() }, { merge: true });
		}
	}

	async removeEmployerAccessToTalentPool(clientIds: Array<string>, talentId: string): Promise<void> {
		for (const clientId of clientIds) {
			const clientQuery = query(collection(db, 'clients'), where('userId', '==', clientId));
			const clientSnapshot = await getDocs(clientQuery);

			if (clientSnapshot.empty) {
				console.error(`No client found with userId: ${clientId}`);
				continue;
			}

			const clientDoc = clientSnapshot.docs[0];
			const clientRef = clientDoc.ref;
			const talentPoolsSubcollectionRef = collection(clientRef, 'talentPools');

			const talentPoolDocRef = doc(talentPoolsSubcollectionRef, talentId);
			const existingTalentPoolSnapshot = await getDocs(talentPoolsSubcollectionRef);

			if (existingTalentPoolSnapshot.docs.some(doc => doc.id === talentId)) {
				await deleteDoc(talentPoolDocRef);
			}
		}
	}

	/**
	 * @method getTalentPool
	 * @async
	 * @param {string} talentPoolId
	 * @returns {Promise<ITalentPool>}
	 */
	async getTalentPool(talentPoolId: string): Promise<ITalentPool> {
		const talentPoolRef = doc(db, 'talentPools', talentPoolId);
		const talentPoolSnap = await getDoc(talentPoolRef);
		const talentPool = mapTalentPoolsForSingleDocument(talentPoolSnap);
		return talentPool;
	}
}
