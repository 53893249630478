<template>
	<v-dialog v-model="openEmployerModal" max-width="1200px">
		<v-card>
			<v-toolbar flat class="fixed-bar" color="#e66832">
				<v-toolbar-title class="title">
					{{ talentPoolName }}
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="closeEmployerModal">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-item>
				<v-row class="d-flex align-center" style="width: 100%">
					<v-col cols="4">
						<v-text-field
							v-model="search"
							label="Search"
							prepend-inner-icon="mdi-magnify"
							variant="outlined"
							hide-details
							single-line
							class="mr-4"
						></v-text-field>
					</v-col>
					<v-col cols="8" class="d-flex justify-space-around">
						<v-btn
							class="btn-jobox"
							prepend-icon="mdi-plus"
							text="Add Talent Pool to All Employers"
							@click="addAllEmployerstoTalentPool"
						></v-btn>
						<v-btn
							class="btn-jobox"
							prepend-icon="mdi-minus"
							text="Remove Talent Pool from All Employers"
							@click="removeTalentPoolFromAllEmployers"
						></v-btn>
					</v-col>
				</v-row>
			</v-card-item>

			<v-card-item>
				<div>
					<v-data-table
						:headers="COMPANY_TABLE_HEADER"
						:search="search"
						:items="employerTalentPool"
						class="elevation-1"
						show-select
						v-model="selectedEmployers"
						item-value="companyName"
					>
						<template #[`item.actions`]="{ item }">
							<div style="text-align: right">
								<v-btn
									v-if="item.access === 'N'"
									class="btn-jobox"
									prepend-icon="mdi-plus"
									@click="assignEmployerToTalentPool(item)"
									>Assign to Talent Pool</v-btn
								>
								<v-btn
									v-else-if="item.access === 'Y'"
									class="btn-jobox"
									prepend-icon="mdi-minus"
									@click="removeEmployerAccessToTalentPool(item)"
									>Remove Access</v-btn
								>
							</div>
						</template>
					</v-data-table>
				</div>
			</v-card-item>
		</v-card>
	</v-dialog>
</template>

<script lang="ts" setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
import { COMPANY_TABLE_HEADER } from '@/constants/table-headers.const';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store/modules/talentPool/action-types';
import { ActionTypes as SpecialProgramActionTypes } from '@/store/modules/specialProgram/action-types';
import { IEmployerUser } from '@/interfaces/employer.interface';

const search = ref('');
const store = useStore();
const emit = defineEmits(['closeEmployerModal', 'fetchEmployers']);

const props = defineProps({
	openEmployerModal: {
		type: Boolean,
		default: false,
	},
	employers: {
		type: Object,
		default: null,
	},
	currentTalentPool: {
		type: Object,
		default: null,
	},
	isSpecialProgram: {
		type: Boolean,
		default: false,
	},
});

const openEmployerModal = computed(() => props.openEmployerModal);
const employersList = computed(() => props.employers);
const currentTalentPool = computed(() => props.currentTalentPool);
const talentPoolName = currentTalentPool.value.name;
const currentClients = ref<string[]>([]);
const selectedEmployers = ref<string[]>([]);
const isSpecialProgram = computed(() => props.isSpecialProgram);

const employerTalentPool = employersList.value
	.map((employer: IEmployerUser) => ({
		...employer,
		access:
			Array.isArray(employer.talentPools) && employer.talentPools.includes(currentTalentPool.value?.talentPoolId)
				? 'Y'
				: 'N',
	}))
	.sort((a, b) => (a.access === b.access ? 0 : a.access === 'Y' ? -1 : 1));

const assignEmployerToTalentPool = async (item: IEmployerUser) => {
	const type = isSpecialProgram.value
		? SpecialProgramActionTypes.ASSIGN_SPECIAL_PROGRAM_TALENT_POOL_TO_EMPLOYER
		: ActionTypes.ASSIGN_TALENT_POOL_TO_EMPLOYER;

	const talentPool = currentTalentPool.value;
	const proceed = confirm(`Assiging ${talentPool.name} to an employer : ${item.companyName} `);
	if (proceed) {
		currentClients.value.push(item.userId);

		await store.dispatch(type, {
			clientIds: currentClients.value,
			talentId: talentPool.talentPoolId,
		});
		emit('closeEmployerModal');
		alert('Success!');
		emit('fetchEmployers');
	}
};

const removeEmployerAccessToTalentPool = async (item: IEmployerUser) => {
	const type = ActionTypes.REMOVE_TALENT_POOL_ACCESS_FROM_EMPLOYER;

	const talentPool = currentTalentPool.value;
	const proceed = confirm(`Removing ${talentPool.name} access from employer : ${item.companyName} `);
	if (proceed) {
		currentClients.value.push(item.userId);

		await store.dispatch(type, {
			clientIds: currentClients.value,
			talentId: talentPool.talentPoolId,
		});
		emit('closeEmployerModal');
		alert('Success!');
		emit('fetchEmployers');
	}
};

const removeTalentPoolFromAllEmployers = async () => {
	const employerTalentList = (
		selectedEmployers.value.length > 0
			? employersList.value.filter((employer: { companyName: string }) =>
					selectedEmployers.value.includes(employer.companyName),
			  )
			: employersList.value
	).map((client: { userId: string }) => client.userId);

	const type = ActionTypes.REMOVE_TALENT_POOL_ACCESS_FROM_EMPLOYER;

	const talentPool = currentTalentPool.value;
	const proceed = confirm(
		`Removing ${talentPool.name} access from ${
			selectedEmployers.value.length > 0 ? selectedEmployers.value.length + ' selected employers' : 'all employers'
		}`,
	);

	if (proceed) {
		await store.dispatch(type, {
			clientIds: employerTalentList,
			talentId: talentPool.talentPoolId,
		});
		emit('closeEmployerModal');
		alert('Success!');
		emit('fetchEmployers');
	}
};

const addAllEmployerstoTalentPool = async () => {
	const employerTalentList = (
		selectedEmployers.value.length > 0
			? employersList.value.filter((employer: { companyName: string }) =>
					selectedEmployers.value.includes(employer.companyName),
			  )
			: employersList.value
	).map((client: { userId: string }) => client.userId);

	const type = isSpecialProgram.value
		? SpecialProgramActionTypes.ASSIGN_SPECIAL_PROGRAM_TALENT_POOL_TO_EMPLOYER
		: ActionTypes.ASSIGN_TALENT_POOL_TO_EMPLOYER;

	const talentPool = currentTalentPool.value;
	const proceed = confirm(
		`Assigning ${talentPool.name} to ${selectedEmployers.value.length > 0 ? 'selected' : 'all'} employers`,
	);
	if (proceed) {
		await store.dispatch(type, {
			clientIds: employerTalentList,
			talentId: talentPool.talentPoolId,
		});
		emit('closeEmployerModal');
		alert('Success!');
		emit('fetchEmployers');
	}
};
const closeEmployerModal = () => {
	emit('closeEmployerModal');
};
</script>
<style scoped>
.title {
	min-width: none;
}
</style>
