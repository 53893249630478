<template>
	<v-dialog v-model="openStudentTalentPool" max-width="1200px">
		<v-card>
			<v-toolbar flat class="fixed-bar" color="#e66832">
				<v-toolbar-title> Add Student to Talent Pool </v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="closeStudentTalentPool">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-item>
				<v-text-field
					v-model="search"
					label="Search"
					prepend-inner-icon="mdi-magnify"
					variant="outlined"
					hide-details
					single-line
					class="mr-4"
					style="flex: 2"
				></v-text-field>
			</v-card-item>
			<v-card-item>
				<div>
					<v-data-table :headers="TALENT_POOL_TABLE_HEADER" :search="search" :items="talentPools" class="elevation-1">
						<template #[`item.actions`]="{ item }">
							<div style="text-align: right">
								<v-btn class="btn-jobox" prepend-icon="mdi-eye-outline" @click="addStudentToTalentPool(item)"
									>Add to Talent Pool</v-btn
								>
							</div>
						</template>
					</v-data-table>
				</div>
			</v-card-item>
		</v-card>
	</v-dialog>
</template>

<script lang="ts" setup>
import { ref, computed, ComputedRef, defineProps, defineEmits } from 'vue';
import { ITalentPool } from '@/interfaces/talentPool.interface';
import { ISpecialProgramTalentPool } from '@/interfaces/specialProgramTalentPool.interface';
import { TALENT_POOL_TABLE_HEADER } from '@/constants/talent-pool.const';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store/modules/talentPool/action-types';
import { ActionTypes as SpecialProgramActionTypes } from '@/store/modules/specialProgram/action-types';

const search = ref('');
const store = useStore();

const emit = defineEmits(['closeStudentTalentPool']);

const props = defineProps({
	openStudentTalentPool: {
		type: Boolean,
		default: false,
	},
	currentStudent: {
		type: Object,
		default: null,
	},
});
type TalentPoolType = ITalentPool | ISpecialProgramTalentPool;

const talentPools = ref<TalentPoolType[]>([]);

const talentPoolList: ComputedRef<ITalentPool[]> = computed(() =>
	Array.from(store.state.talentPool.talentPoolList.records.values()),
);

const specialProgramTalentPoolList: ComputedRef<ISpecialProgramTalentPool[]> = computed(() =>
	Array.from(store.state.specialProgramTalentPool.specialProgramTalentPoolList.records.values()),
);

const combinedTalentPools = computed<TalentPoolType[]>(() => [
	...talentPoolList.value,
	//...specialProgramTalentPoolList.value,
]);

talentPools.value = combinedTalentPools.value;
const openStudentTalentPool = computed(() => props.openStudentTalentPool);
const currentStudent = computed(() => props.currentStudent);

const addStudentToTalentPool = async (item: any) => {
	const students = currentStudent.value;
	if (students.length > 0) {
		const proceed = confirm(
			`${students.length} student(s) will be added to the '${item.name}' talent pool. Any duplicates will be left out.`,
		);

		if (proceed) {
			// const type = findSpecialProgramTalentPoolById(item.talentPoolId)
			// 	? SpecialProgramActionTypes.ADD_STUDENT_TO_SPECIAL_PROGRAM_TALENT_POOL
			// 	: ActionTypes.ADD_STUDENT_TO_TALENT_POOL;

			await store.dispatch(ActionTypes.ADD_STUDENT_TO_TALENT_POOL, {
				talentPoolId: item.talentPoolId,
				students: students,
			});
			alert('Student(s) has been added to the talent pool');
			emit('closeStudentTalentPool');
		}
	} else {
		alert('Please select a student to add to the talent pool.');
	}
};
const closeStudentTalentPool = () => {
	emit('closeStudentTalentPool');
};

const findSpecialProgramTalentPoolById = (id: string): ISpecialProgramTalentPool | undefined => {
	return specialProgramTalentPoolList.value.find(item => item.talentPoolId === id);
};
</script>
