import { BursaryService } from '@/services/bursary.service';
import { ActionContext, ActionTree } from 'vuex';
import Container from 'typedi';
import { RootState } from '../root/state';
import { State } from './state';
import { ActionTypes } from './action-types';
import { RootMutationTypes } from '../root/mutation-types';
import { MutationTypes } from '../bursary/mutation-types';

const bursaryService = Container.get(BursaryService);

export interface Actions {
	[ActionTypes.GET_BURSARY_LIST]({ commit }: ActionContext<State, RootState>): void;
	[ActionTypes.RESUBMIT_EMAIL_SENT](
		{ commit, dispatch }: ActionContext<State, RootState>,
		bursaryApplicationId: string,
	): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ActionTypes.GET_BURSARY_LIST]({ commit }) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const bursaryList = await bursaryService.getBursaries();
			commit(MutationTypes.SET_BURSARY_LIST, bursaryList);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(RootMutationTypes.SET_ERROR_MSG, e?.message || 'Problems with getting bursaries. Please try again later.');
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.GET_BURSARY_APPLICANTS]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			const applicantList = await bursaryService.getBursaryApplications(payload.bursaryId);
			const studentIds = [...applicantList.records.values()].map(s => s.alias);
			commit(MutationTypes.SET_BURSARY_APPLICANTS, applicantList);
			if (studentIds?.length) {
				await dispatch('GET_STUDENT_LIST', { studentIds });
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with getting applicants. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.APPROVE_DOCUMENT]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await bursaryService.approveBursaryDoc(payload);
			await dispatch(ActionTypes.GET_BURSARY_APPLICANTS);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with updating document status. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.RESUBMIT_EMAIL_SENT]({ commit, dispatch }, bursaryApplicationId) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await bursaryService.resubmitEmailSent(bursaryApplicationId);
			await dispatch(ActionTypes.GET_BURSARY_APPLICANTS);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with updating email status. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
	async [ActionTypes.UPDATE_BURSARY_APPLICANT_STATUS]({ commit, dispatch }, payload) {
		commit(RootMutationTypes.SET_LOADER, { loading: true });
		try {
			await bursaryService.updateBursaryApplicantStatus(payload.bursaryApplicationId, payload.status);
			await dispatch(ActionTypes.GET_BURSARY_APPLICANTS, { bursaryId: payload.bursaryId });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			commit(
				RootMutationTypes.SET_ERROR_MSG,
				e?.message || 'Problems with shortlisting the applicant. Please try again later.',
			);
		} finally {
			commit(RootMutationTypes.SET_LOADER, { loading: false });
		}
	},
};
