import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { State } from './state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { IBursary, IStudentApplication } from '@/interfaces/bursary.interface';

export type Mutations<S = State> = {
	[MutationTypes.SET_BURSARY_LIST](state: S, payload: IPaginatedItems<IBursary>): void;
	[MutationTypes.SET_BURSARY](state: S, payload: { bursaryId: string }): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_BURSARY_LIST](state: State, payload: IPaginatedItems<IBursary>) {
		state.bursaryList = payload;
	},
	[MutationTypes.SET_BURSARY](state: State, payload: { bursaryId: string }) {
		state.bursary = state.bursaryList.records.get(payload.bursaryId);
	},
	[MutationTypes.SET_BURSARY_APPLICANTS](state: State, payload: IPaginatedItems<IStudentApplication>) {
		state.bursaryApplicants = payload;
	},
};
