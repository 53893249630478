<template>
	<div>
		<Suspense>
			<div class="table-container">
				<h1 class="text-center">Students</h1>
				<v-row>
					<v-col cols="6" class="justify-start">
						Total Students Found:
						<span style="font-weight: bold">{{ filteredStudents.length }}</span>
					</v-col>
					<v-col cols="6" class="justify-end">
						<v-btn
							v-if="(isListFiltered && filteredStudents.length !== 0) || selectedStudents.length > 0"
							class="btn-jobox"
							prepend-icon="mdi-plus"
							text="Add Students to Talent Pool"
							@click="addStudentstoTalentPool"
						></v-btn>

						&nbsp;
						<v-btn
							class="btn-jobox"
							prepend-icon="mdi-plus"
							text="Create Talent Pool"
							@click="createTalentPool"
						></v-btn>
						&nbsp;
						<v-btn
							class="btn-jobox-outline"
							prepend-icon="mdi-filter-outline"
							variant="outlined"
							@click="toggleFilterMenu"
						>
							Filter
						</v-btn>
						&nbsp;
						<v-btn
							class="btn-jobox-outline"
							prepend-icon="mdi-export-variant"
							variant="outlined"
							@click="exportCsv(exportStudents, 'talent-db-students.csv')"
						>
							Export
						</v-btn>
					</v-col>
				</v-row>

				<v-card flat>
					<template v-slot:text>
						<v-text-field
							v-model="search"
							label="Search"
							prepend-inner-icon="mdi-magnify"
							variant="outlined"
							hide-details
							single-line
						></v-text-field>
					</template>

					<v-list>
						<v-list-item v-for="student in paginatedStudents" :key="student.alias">
							<v-container class="rounded border-sm">
								<v-row>
									<v-col cols="1">
										<v-checkbox v-model="selectedStudents" :value="student" color="primary"></v-checkbox>
									</v-col>
									<v-col cols="2">
										<v-avatar size="75">
											<v-img class="profile-photo profile-photo-small" :src="student.profile || DEFAULT_AVATAR"></v-img>
										</v-avatar>
									</v-col>
									<v-col cols="4" class="pl-0">
										<b>{{ student.name }} {{ student.surname }}</b
										><br />
										<span style="color: grey">{{ student.qualifications[0]?.degree }}</span>
									</v-col>
									<v-col cols="5" class="justify-end pr-0">
										<v-btn class="btn-jobox" prepend-icon="mdi-account-outline" @click="viewStudent(student)">
											View Profile
										</v-btn>
										&nbsp;
										<v-btn class="btn-jobox-outline" prepend-icon="mdi-playlist-plus" variant="outlined" disabled>
											Add to Segment
										</v-btn>
										&nbsp;
										<v-menu>
											<template #activator="{ props }">
												<v-btn color="transparent" v-bind="props" flat>
													<v-icon>mdi-dots-horizontal</v-icon>
												</v-btn>
											</template>
											<v-list>
												<v-list-item>Send Campaign</v-list-item>
												<v-list-item @click="addToTalentPool(student)"> Add to Talent Pool </v-list-item>
											</v-list>
										</v-menu>
									</v-col>
								</v-row>
							</v-container>
						</v-list-item>
					</v-list>

					<v-pagination v-model="pageNumber" :length="pageCount" @input="changePage"></v-pagination>
				</v-card>

				<StudentFilter
					:isFilterClicked="isFilterClicked"
					@update:isClosed="handleFilterClose"
					@filterChanged="handleFilterChange"
					:uniqueInstitutions="uniqueInstitutions"
					:uniqueQualificationTypes="uniqueQualificationTypes"
					:uniqueYearOfStudy="uniqueYearOfStudy"
					:uniqueDegrees="uniqueDegrees"
					:uniqueSkills="uniqueSkills"
					:uniqueInterestedDepartments="uniqueInterestedDepartments"
					:uniquePreferredEmployers="uniquePreferredEmployers"
					:uniqueProgrammes="uniqueProgrammes"
					:uniqueRaces="uniqueRaces"
					:uniqueGenders="uniqueGenders"
					:uniqueDriversLicense="uniqueDriversLicense"
					:uniqueVehicles="uniqueVehicles"
					:uniqueDisability="uniqueDisability"
					:uniqueCitizenships="uniqueCitizenships"
					:uniqueLocations="uniqueLocations"
					:students="studentList || []"
				/>
			</div>
		</Suspense>
		<StudentModal
			v-if="isStudentModalOpen"
			@close="closeStudentModal"
			@openTalentPoolStudentModal="openTalentPoolStudentModal"
		/>
		<TalentPoolCreateModal
			v-if="isTalentPoolOpen"
			:isStudentSideCreate="isStudentSideCreate"
			:openTalentPool="isTalentPoolOpen"
			@close="closeTalentModal"
			@closeTalentPool="closeTalentModal"
		/>

		<TalentPoolModal
			v-if="openStudentTalentPool"
			:openStudentTalentPool="openStudentTalentPool"
			@closeStudentTalentPool="closeStudentTalentPool"
			:currentStudent="currentStudent"
		/>
	</div>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import StudentFilter from '@/components/Filters/StudentFilter.vue';
import { MutationTypes } from '@/store/modules/student/mutation-types';
import { IStudentUser } from '@/interfaces/student.interface';
import { exportCsv } from '@/utils/file.util';
import StudentModal from '@/components/StudentModal.vue';
import TalentPoolCreateModal from '@/components/TalentPoolCreateModal.vue';
import TalentPoolModal from '@/components/TalentPoolModal.vue';
import { DEFAULT_AVATAR } from '@/constants';
import { ActionTypes } from '@/store/modules/student/action-types';

const search = ref('');
const isStudentModalOpen = ref(false);
const isFilterClicked = ref(false);
const openStudentTalentPool = ref(false);
const selectedStudents = ref([]);
const isStudentSideCreate = ref(true);

// Filters
const selectedInstitutions = ref<string[]>([]);
const selectedQualificationTypes = ref<string[]>([]);
const selectedYearOfStudy = ref<string[]>([]);
const selectedDegrees = ref<string[]>([]);
const selectedSkills = ref<string[]>([]);
const selectedInterestedDepartments = ref<string[]>([]);
const selectedPreferredEmployers = ref<string[]>([]);
const selectedProgrammes = ref<string[]>([]);
const selectedRaces = ref<string[]>([]);
const selectedGenders = ref<string[]>([]);
const selectedDriversLicenses = ref<string[]>([]);
const selectedVehicles = ref<string[]>([]);
const selectedDisabilities = ref<string[]>([]);
const selectedCitizenships = ref<string[]>([]);
const selectedLocations = ref<string[]>([]);

const pageNumber = ref(1);
const pageSize = ref(10);
const isTalentPoolOpen = ref(false);
const currentStudent = ref<IStudentUser[]>([]);

const store = useStore();

const createTalentPool = () => {
	isTalentPoolOpen.value = true;
};
const closeTalentModal = () => {
	isTalentPoolOpen.value = false;
};

const addToTalentPool = (item: any) => {
	openStudentTalentPool.value = true;
	currentStudent.value.push(item);
};

const openTalentPoolStudentModal = () => {
	openStudentTalentPool.value = true;
};

const addStudentstoTalentPool = async () => {
	if (!selectedStudents.value && !filteredStudents.value) return;

	openStudentTalentPool.value = true;
	currentStudent.value.push(...(selectedStudents.value.length > 0 ? selectedStudents.value : filteredStudents.value));
};

const closeStudentTalentPool = () => {
	openStudentTalentPool.value = false;
	currentStudent.value = [];
	selectedStudents.value = [];
};

const studentList: ComputedRef<IStudentUser[]> = computed(() => [...store.state.student.studentList.records.values()]);

const uniqueInstitutions = computed(() =>
	Array.from(
		new Set(studentList.value.flatMap(s => s.qualifications.filter(q => q.institution).map(q => q.institution))),
	),
);

const uniqueQualificationTypes = computed(() =>
	Array.from(
		new Set(studentList.value.flatMap(s => s.qualifications.filter(q => q.graduateStatus).map(q => q.graduateStatus))),
	),
);

const uniqueDegrees = computed(() =>
	Array.from(new Set(studentList.value.flatMap(s => s.qualifications.filter(q => q.degree).map(q => q.degree)))),
);

const uniqueYearOfStudy = computed(() =>
	Array.from(
		new Set(studentList.value.flatMap(s => s.qualifications.filter(q => q.year !== null).map(q => q.year.toString()))),
	),
);

const uniqueSkills = computed(() => Array.from(new Set(studentList.value.flatMap(s => s.skills ?? []))));

const uniqueInterestedDepartments = computed(() =>
	Array.from(new Set(studentList.value.flatMap(s => s.preferredCompanies.department?.map(d => d.department) ?? []))),
);

const uniquePreferredEmployers = computed(() =>
	Array.from(new Set(studentList.value.flatMap(s => s.preferredCompanies.choices ?? []))),
);

const uniqueProgrammes = computed(() => Array.from(new Set(studentList.value.map(s => s.programme))));
const uniqueRaces = computed(() => Array.from(new Set(studentList.value.map(s => s.race))));
const uniqueGenders = computed(() => Array.from(new Set(studentList.value.map(s => s.gender))));
const uniqueDriversLicense = computed(() => Array.from(new Set(studentList.value.map(s => s.license))));
const uniqueVehicles = computed(() => Array.from(new Set(studentList.value.map(s => s.vehicle))));
const uniqueDisability = computed(() => Array.from(new Set(studentList.value.map(s => s.disability))));
const uniqueCitizenships = computed(() => Array.from(new Set(studentList.value.map(s => s.citizenship))));
const uniqueLocations = computed(() => Array.from(new Set(studentList.value.map(s => s.location?.town))));

const filteredStudents = computed(() => {
	return studentList.value.filter(student => {
		const matchesSearch =
			!search.value ||
			student.name.toLowerCase().includes(search.value.toLowerCase()) ||
			student.surname.toLowerCase().includes(search.value.toLowerCase());

		const matchesInstitutions =
			selectedInstitutions.value.length === 0 ||
			selectedInstitutions.value.some(i => student.qualifications.some(q => q.institution === i));

		const matchesQualificationTypes =
			selectedQualificationTypes.value.length === 0 ||
			selectedQualificationTypes.value.some(qt => student.qualifications.some(q => q.graduateStatus === qt));

		const matchesDegrees =
			selectedDegrees.value.length === 0 ||
			selectedDegrees.value.some(d => student.qualifications.some(q => q.degree === d));

		const matchesYearOfStudy =
			selectedYearOfStudy.value.length === 0 ||
			selectedYearOfStudy.value.some(ys =>
				student.qualifications.some(q => q.year !== null && q.year.toString() === ys),
			);

		const matchesSkills =
			selectedSkills.value.length === 0 || selectedSkills.value.some(skill => student.skills?.includes(skill) ?? false);

		const matchesInterestedDepartments =
			selectedInterestedDepartments.value.length === 0 ||
			selectedInterestedDepartments.value.some(
				id =>
					student.preferredCompanies.department?.some(d => d.department === id || (d as unknown as string) === id) ??
					false,
			);

		const matchesPreferredEmployers =
			selectedPreferredEmployers.value.length === 0 ||
			selectedPreferredEmployers.value.some(pe => student.preferredCompanies.choices?.includes(pe) ?? false);

		const matchesProgrammes =
			selectedProgrammes.value.length === 0 || selectedProgrammes.value.includes(student.programme);

		const matchesRaces = selectedRaces.value.length === 0 || selectedRaces.value.includes(student.race);

		const matchesGenders = selectedGenders.value.length === 0 || selectedGenders.value.includes(student.gender);

		const matchesDriversLicenses =
			selectedDriversLicenses.value.length === 0 || selectedDriversLicenses.value.includes(student.license);

		const matchesVehicles = selectedVehicles.value.length === 0 || selectedVehicles.value.includes(student.vehicle);

		const matchesDisabilities =
			selectedDisabilities.value.length === 0 || selectedDisabilities.value.includes(student.disability);

		const matchesCitizenships =
			selectedCitizenships.value.length === 0 || selectedCitizenships.value.includes(student.citizenship);

		const matchesLocations =
			selectedLocations.value.length === 0 || selectedLocations.value.includes(student.location?.town);

		return (
			matchesSearch &&
			matchesInstitutions &&
			matchesQualificationTypes &&
			matchesYearOfStudy &&
			matchesDegrees &&
			matchesSkills &&
			matchesInterestedDepartments &&
			matchesPreferredEmployers &&
			matchesProgrammes &&
			matchesRaces &&
			matchesGenders &&
			matchesDriversLicenses &&
			matchesVehicles &&
			matchesDisabilities &&
			matchesCitizenships &&
			matchesLocations
		);
	});
});

const isListFiltered = computed(() => {
	return (
		selectedInstitutions.value.length > 0 ||
		selectedQualificationTypes.value.length > 0 ||
		selectedYearOfStudy.value.length > 0 ||
		selectedDegrees.value.length > 0 ||
		selectedSkills.value.length > 0 ||
		selectedInterestedDepartments.value.length > 0 ||
		selectedPreferredEmployers.value.length > 0 ||
		selectedProgrammes.value.length > 0 ||
		selectedRaces.value.length > 0 ||
		selectedGenders.value.length > 0 ||
		selectedDriversLicenses.value.length > 0 ||
		selectedVehicles.value.length > 0 ||
		selectedDisabilities.value.length > 0 ||
		selectedCitizenships.value.length > 0 ||
		selectedLocations.value.length > 0 ||
		search.value.trim() !== ''
	);
});

watchEffect(() => {
	filteredStudents.value;
});

const paginatedStudents = computed(() => {
	const start = (pageNumber.value - 1) * pageSize.value;
	const end = start + pageSize.value;
	return filteredStudents.value.slice(start, end);
});

const pageCount = computed(() => Math.ceil(filteredStudents.value.length / pageSize.value));

const viewStudent = (student: any) => {
	const studentId = student.alias;
	store.commit(MutationTypes.SET_STUDENT, { studentId });
	isStudentModalOpen.value = true;
	currentStudent.value.push(student);
};

const closeStudentModal = () => {
	isStudentModalOpen.value = false;
};

const toggleFilterMenu = () => {
	isFilterClicked.value = !isFilterClicked.value;
};

const handleFilterClose = (filterClose = false) => {
	isFilterClicked.value = filterClose;
};

const handleFilterChange = (filters: { [key: string]: string[] }) => {
	selectedInstitutions.value = filters.institutions || [];
	selectedQualificationTypes.value = filters.qualificationTypes || [];
	selectedYearOfStudy.value = filters.yearOfStudy || [];
	selectedDegrees.value = filters.degrees || [];
	selectedSkills.value = filters.skills || [];
	selectedInterestedDepartments.value = filters.interestedDepartments || [];
	selectedPreferredEmployers.value = filters.preferredEmployers || [];
	selectedProgrammes.value = filters.programmes || [];
	selectedRaces.value = filters.races || [];
	selectedGenders.value = filters.genders || [];
	selectedDriversLicenses.value = filters.driversLicense || [];
	selectedVehicles.value = filters.vehicles || [];
	selectedDisabilities.value = filters.disability || [];
	selectedCitizenships.value = filters.citizenships || [];
	selectedLocations.value = filters.locations || [];
};

const changePage = (page: number) => {
	pageNumber.value = page;
};

const exportStudents = computed(() =>
	filteredStudents.value.map(s => {
		return {
			Name: `${s.name} ${s.surname}`,
			Email: s.email,
			Phone: s.phone,
			Institution: s.qualifications[0]?.institution,
			Degree: s.qualifications[0]?.degree,
			'Year of Study': s.qualifications[0]?.year,
		};
	}),
);
</script>
