import { RouteRecordRaw } from 'vue-router';

import TalentPool from '@/views/Dashboard/talentPool/TalentPool.vue';
import ViewTalentPool from '@/views/Dashboard/talentPool/ViewTalentPool.vue';
import BaseLayout from '@/components/BaseLayout.vue';
import store from '@/store';
import { ActionTypes } from '@/store/modules/talentPool/action-types';
import { ActionTypes as EmployerActionTypes } from '@/store/modules/employer/action-types';
import { ActionTypes as StudentActionTypes } from '@/store/modules/student/action-types';

export const TalentPoolRoute: RouteRecordRaw = {
	path: '/talent-pool',
	component: BaseLayout,
	children: [
		{
			path: '',
			name: 'talent-pool',
			component: TalentPool,
			beforeEnter: async () => {
				await store.dispatch(ActionTypes.GET_TALENT_POOL_LIST);
				await store.dispatch(EmployerActionTypes.GET_EMPLOYERS);
			},
		},
		{
			path: '/:talentPoolId',
			name: 'current-talent-pool',
			component: ViewTalentPool,
			beforeEnter: async (to, from, next) => {
				try {
					await store.dispatch(ActionTypes.GET_TALENT_POOL, { talentPoolId: to.params.talentPoolId });
					await store.dispatch(StudentActionTypes.GET_STUDENT_LIST);
					next();
				} catch (error) {
					console.error('Error fetching talent pool:', error);
					next(false);
				}
			},
		},
	],
};
