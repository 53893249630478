import { GetterTree } from 'vuex';

import { State } from './state';
import { RootState } from '../root/state';
import { IPaginatedItems } from '@/interfaces/paginated.interface';
import { ITalentPool } from '@/interfaces/talentPool.interface';

export type Getters = {
	getTalentPools(state: State): IPaginatedItems<ITalentPool>;
};

export const getters: GetterTree<State, RootState> & Getters = {
	getTalentPools: state => {
		return state.talentPoolList;
	},
};
